import MicroEvent from '@vivotek/lib-utility/microevent';
import StreamProcessor from './StreamProcessor';
import RtspHandler from './handler/RtspHandler';
import VideoPlayer from './player/VideoPlayer';
import CanvasPlayer from './player/CanvasPlayer';
import AudioPlayer from './player/AudioPlayer';

class Liveview {
  constructor(options) {
    this.options = options;
    this.streamInfo = null;
    this.packetTimeout = null

    this.rtspProcessor = this.createRtspHandler(options);
    this.streamProcessor = new StreamProcessor(options);
    this.streamPlayer = null;
    this.audioPlayer = new AudioPlayer(options);
    this.bindEvents();
  }

  bindEvents() {
    this.$_processRtspPacket = this.processRtspPacket.bind(this);
    this.$_processRtspInfo = this.processRtspInfo.bind(this);
    this.rtspProcessor.on('packet', this.$_processRtspPacket);
    this.rtspProcessor.on('info', this.$_processRtspInfo);

    this.$_processStreamVideo = this.processStreamVideo.bind(this);
    this.$_processStreamAudio = this.processStreamAudio.bind(this);
    this.$_processStreamInfo = this.processStreamInfo.bind(this);
    this.$_processStreamHevc = this.processStreamHevc.bind(this);
    this.$_processStreamMetj = this.processStreamMetj.bind(this);
    this.$_processStreamMetx = this.processStreamMetx.bind(this);
    this.streamProcessor.on('video', this.$_processStreamVideo);
    this.streamProcessor.on('audio', this.$_processStreamAudio);
    this.streamProcessor.on('info', this.$_processStreamInfo);
    this.streamProcessor.on('hevc', this.$_processStreamHevc);
    this.streamProcessor.on('metj', this.$_processStreamMetj);
    this.streamProcessor.on('metx', this.$_processStreamMetx);
  }

  unbinedEvents() {
    this.rtspProcessor.off('packet', this.$_processRtspPacket);
    this.rtspProcessor.off('info', this.$_processRtspInfo);

    this.streamProcessor.off('video', this.$_processStreamVideo);
    this.streamProcessor.off('audio', this.$_processStreamAudio);
    this.streamProcessor.off('info', this.$_processStreamInfo);
    this.streamProcessor.off('hevc', this.$_processStreamHevc);
    this.streamProcessor.off('metj', this.$_processStreamMetj);
    this.streamProcessor.off('metx', this.$_processStreamMetx);
  }

  processRtspPacket(packet) {
    this.streamProcessor.appendPacket(packet);
  }

  processRtspInfo(info) {
    if (info.error) {
      this.trigger('error', info.error);
    } else {
      this.processStreamInfo(info);
    }
  }

  processStreamVideo({ packet }) {
    const { streamInfo, streamPlayer } = this;

    if (!streamInfo) {
      return;
    }

    if (!streamPlayer) {
      this.streamPlayer = this.createVideoPlayer();
    }
    this.streamPlayer.appendPacket({ packet, info: streamInfo });
  }

  processStreamAudio({ packet, sampleRate, duration }) {
    const { streamInfo, audioPlayer } = this;

    audioPlayer.appendAudioPacket({
      packet, sampleRate, duration,
      info: streamInfo,
    });
  }

  processStreamHevc({ packet }) {
    const { streamInfo, streamPlayer } = this;

    if (!streamInfo) {
      return;
    }

    if (!streamPlayer) {
      this.streamPlayer = this.createCanvasPlayer({
        codec: streamInfo.codec, ...streamInfo.resolution
      });
    }
    this.streamPlayer.appendPacket({ packet, info: streamInfo });
  }

  processStreamInfo(info) {
    this.streamInfo = info;
  }

  processStreamMetj(metj) {
    this.streamPlayer.appendMetj(metj);
  }

  processStreamMetx(xmlDoc) {
    this.streamPlayer.appendMetx(xmlDoc);
  }

  createRtspHandler(options={}) {
    return new RtspHandler(options);
  }

  createVideoPlayer(options={}) {
    const videoPlayer = new VideoPlayer({
      ...options,
    });

    this.$_onPlay = this.onPlay.bind(this);
    this.$_onPause = this.onPause.bind(this);
    this.$_onTimeupdate = this.onTimeupdate.bind(this);
    this.$_onNotify = this.onNotify.bind(this);
    this.$_onError = this.onError.bind(this);

    videoPlayer.on('play', this.$_onPlay);
    videoPlayer.on('pause', this.$_onPause);
    videoPlayer.on('timeupdate', this.$_onTimeupdate);
    videoPlayer.on('notify', this.$_onNotify);
    videoPlayer.on('error', this.$_onError);

    return videoPlayer;
  }

  createCanvasPlayer(options) {
    const { mute, volume, workerLibde264Path, workerLibde265Path } = this.options;
    const canvasPlayer = new CanvasPlayer({
      ...options,
      workerLibde264Path, workerLibde265Path, mute, volume,
    });

    this.$_onPlay = this.onPlay.bind(this);
    this.$_onPause = this.onPause.bind(this);
    this.$_onTimeupdate = this.onTimeupdate.bind(this);
    this.$_onNotify = this.onNotify.bind(this);
    this.$_onError = this.onError.bind(this);

    canvasPlayer.on('play', this.$_onPlay);
    canvasPlayer.on('pause', this.$_onPause);
    canvasPlayer.on('timeupdate', this.$_onTimeupdate);
    canvasPlayer.on('notify', this.$_onNotify);
    canvasPlayer.on('error', this.$_onError);

    return canvasPlayer;
  }

  removeStreamPlayer() {
    const { streamPlayer } = this;

    streamPlayer.off('play', this.$_onPlay);
    streamPlayer.off('pause', this.$_onPause);
    streamPlayer.off('timeupdate', this.$_onTimeupdate);
    streamPlayer.off('notify', this.$_onNotify);
    streamPlayer.off('error', this.$_onError);
    streamPlayer.destroy();

    delete this.$_onPlay;
    delete this.$_onPause;
    delete this.$_onTimeupdate;
    delete this.$_onNotify;
    delete this.$_onError;

    this.streamPlayer = null;
  }

  onPlay() {
    this.trigger('play', this.getPlayer());
    this.setPlayerTimeout();
  }

  onTimeupdate() {
    this.setPlayerTimeout();

    const { audioPlayer , streamPlayer, currentTime} = this;

    if (!audioPlayer.inited) {
      const refNotify = streamPlayer.getReferenceNotify();
      if (!refNotify) {
        return;
      }
      audioPlayer.initAudio(currentTime * 1000, refNotify.timestamp);
    }

    audioPlayer.processAudio();
  }

  onNotify({ type, event }) {
    this.trigger(type, event);
  }

  onPause() {}

  onError(err) {
    this.trigger('error', err);
  }

  play() {
    return this.rtspProcessor.play();
  }

  stop() {
    return this.rtspProcessor.stop()
      .then(() => {
        this.streamPlayer.stop();
        this.audioPlayer.switchAudio();
        this.clearPlayerTimeout();
      });
  }

  destroy() {
    this.unbinedEvents();
    this.removeStreamPlayer();
    this.streamProcessor.destroy();
  }

  get currentTime() {
    return this.getPlayerCurrentTime();
  }

  getPlayerCurrentTime() {
    return this.streamPlayer?.currentTime || 0;
  }

  getPlayer() {
    const { streamPlayer } = this;
    if (streamPlayer instanceof CanvasPlayer) {
      return streamPlayer.canvas;
    } else {
      return streamPlayer.video;
    }
  }

  setPlayerTimeout() {
    this.clearPlayerTimeout();

    this.packetTimeout = setTimeout(
      () => this.onError(new Error('Player Timeout')),
      10 * 1000
    );
  }

  clearPlayerTimeout() {
    if (this.packetTimeout) {
      clearTimeout(this.packetTimeout);
    }

    this.packetTimeout = null;
  }

  setMute() {
    this.audioPlayer?.setMute();
  }

  unmute() {
    this.audioPlayer?.unmute();
  }

  setVolume(volume) {
    this.audioPlayer?.setVolume(volume);
  }
}

export default MicroEvent.mixin(Liveview);
