import MicroEvent from '@vivotek/lib-utility/microevent';
import ModuleSmartStream from '@vivotek/ssmux';
import setSsmuxDebugLevel from './utils/set_ssmux_debug_level';

class StreamProcessor {
  constructor({ ssmuxType = 0, snapshotMode = false }) {
    this.snapshotMode = snapshotMode;

    const processVideoEvent = this.processVideoEvent.bind(this);
    const processAudioEvent = this.processAudioEvent.bind(this);
    const processInfoEvent = this.processInfoEvent.bind(this);
    const processHEVCEvent = this.processHEVCEvent.bind(this);
    const processMETJEvent = this.processMETJEvent.bind(this);
    const processMETXEvent = this.processMETXEvent.bind(this);

    new ModuleSmartStream().then(ssmux => {
      this.ssmux = ssmux;

      ssmux.initial(ssmuxType);
      ssmux.setFMP4Callback(processVideoEvent);
      ssmux.setPCMCallback(processAudioEvent);
      ssmux.setInfoCallback(processInfoEvent);
      ssmux.setHEVCCallback(processHEVCEvent);
      ssmux.setMETJCallback(processMETJEvent);
      ssmux.setMETXCallback(processMETXEvent);

      setSsmuxDebugLevel(ssmux);
    });
  }

  releaseSsmux() {
    const { ssmux } = this;
    ssmux.setFMP4Callback(null);
    ssmux.setPCMCallback(null);
    ssmux.setInfoCallback(null);
    ssmux.setHEVCCallback(null);
    ssmux.setMETJCallback(null);
    ssmux.setMETXCallback(null);
    ssmux.release();
    // ssmux.delete(); // <<<<< call delete if you need to delete ssmux instance
    this.ssmux = null;
  }

  appendPacket(packet, continued=false) {
    const { ssmux } = this;
    const arr = new Uint8Array(packet);
    // eslint-disable-next-line no-underscore-dangle
    const buf = ssmux._malloc(arr.length);
    ssmux.HEAP8.set(arr, buf);
    ssmux.inputPacketV1(buf, arr.length, continued);
    // eslint-disable-next-line no-underscore-dangle
    ssmux._free(buf);
  }

  processVideoEvent(packet) {
    this.trigger('video', { packet: packet.slice() });
  }

  processAudioEvent(packet, sampleRate, duration) {
    if (this.snapshotMode) {
      return;
    }

    this.trigger('audio', { packet: packet.slice(), sampleRate, duration });
  }

  processInfoEvent(ptr) {
    if (this.snapshotMode) {
      return;
    }

    try {
      this.trigger('info', JSON.parse(ptr));
    } catch (err) {
      console.error(err, ptr);
    }
  }

  processHEVCEvent(packet) {
    this.trigger('hevc', { packet: packet.slice() });
  }

  processMETJEvent(ptr) {
    try {
      this.trigger('metj', JSON.parse(ptr));
    } catch (err) {
      console.error(err, ptr);
    }
  }

  processMETXEvent(text) {
    this.trigger('metx', new DOMParser().parseFromString(text, 'text/xml'));
  }

  destroy() {
    this.releaseSsmux();
  }
}

export default MicroEvent.mixin(StreamProcessor);
