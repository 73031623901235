(function (global) {
  /* DO NOT modify this file, it's generated from nr/scripts/device_pack_rt.ejs */

  var rt = genRTMapping();

  if (typeof define === 'function' && define.amd) {
    define(rt);
  }

  injectObject(global, 'NR.Models.Profile.DevicePackRT', rt);

  function injectObject (node, namepath, obj) {
    function isContainer (node) {
      var type = typeof node;
      return (type == 'object' || type == 'function');
    }
    if (!isContainer(node)) {
      return;
    }
    var path_parts = namepath.split('.');
    var path_tail = path_parts.pop();
    var l = path_parts.length;
    var i, cur;

    for (i = 0; i < l; ++i) {
      cur = path_parts[i];
      if (node[cur] == null) {
        node = node[cur] = {};
        continue;
      }
      node = node[cur];
      if (!isContainer(node)) {
        return;
      }
    }
    node[path_tail] = obj;
  }

  function genRTMapping () {
    var rt = {};

    rt["CC8130"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["CC8370-HV"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8133"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8134"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((n.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(n.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((q.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((q.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((q.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(q.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  w.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  w.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  x.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  x.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  o = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((o.currentOpt == "1"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "2"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "3"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(o.currentOpt) > Number("3")))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8135"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8136"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8137"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8138-H"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8151"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8152"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  i.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  i.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  i.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  i.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8152V"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8154"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["FD8155H"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Dual Stream (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","720x480","800x600","960x768","1280x720","1280x1024"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  c.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  d.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  e.opts = ["H.264","JPEG"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  f.opts = ["176x144","320x240","640x480","720x480","800x600","960x768","1280x720","1280x1024"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_h264_constant_bitrate");
  g.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_constant_bitrate");
  h.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  i.opts = ["H.264","JPEG"];
  }
  else if (((a.currentOpt == "Video Rotation"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","720x480","800x600","960x768","1280x720","1280x1024"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  c.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  e.opts = ["H.264"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  f.opts = ["176x144","320x240","640x480","720x480","800x600","960x768","1280x720","1280x1024"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_h264_constant_bitrate");
  g.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  i.opts = ["H.264"];
  }
  else if (((a.currentOpt == "Single Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","720x480","800x600","960x768","1280x720","1280x1024"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  c.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  d.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  e.opts = ["H.264","JPEG"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((j.currentOpt == "50") && (a.currentOpt == "Dual Stream (4:3)"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((j.currentOpt == "60") && (a.currentOpt == "Dual Stream (4:3)"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((j.currentOpt == "50") && (a.currentOpt == "Video Rotation"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((j.currentOpt == "60") && (a.currentOpt == "Video Rotation"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((j.currentOpt == "50") && (a.currentOpt == "Single Stream"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  }
  else if (((j.currentOpt == "60") && (a.currentOpt == "Single Stream"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((j.currentOpt == "50"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((j.currentOpt == "60"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["FD8156"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["FD8161"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "0"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200"];
  }
  else if (((a.currentOpt == "1"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600"];
  }
  else if (((a.currentOpt == "2"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["320x176","640x360","1280x720"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["320x176","640x360","1280x720"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["320x176","640x360","1280x720"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["320x176","640x360","1280x720"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((f.currentOpt == "50") && (a.currentOpt == "0"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "0"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15"];
  }
  else if (((f.currentOpt == "50") && (a.currentOpt == "1"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((f.currentOpt == "50") && (a.currentOpt == "2"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "2"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  w.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  w.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((o.currentOpt == "1"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "2"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "3"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(o.currentOpt) > Number("3")))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((p.currentOpt == "1"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((p.currentOpt == "2"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((p.currentOpt == "3"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(p.currentOpt) > Number("3")))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((r.currentOpt == "1"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((r.currentOpt == "2"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((r.currentOpt == "3"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(r.currentOpt) > Number("3")))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8162"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8163"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["FD8164"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["FD8165H"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Dual Stream (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  c.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  d.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  e.opts = ["H.264","JPEG"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  f.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_h264_constant_bitrate");
  g.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_constant_bitrate");
  h.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  i.opts = ["H.264","JPEG"];
  }
  else if (((a.currentOpt == "Video Rotation"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  c.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  e.opts = ["H.264"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  f.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_h264_constant_bitrate");
  g.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  i.opts = ["H.264"];
  }
  else if (((a.currentOpt == "Single Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  c.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M","18M","20M","24M","28M","32M"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  d.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M","18M","20M","24M","28M","32M"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  e.opts = ["H.264","JPEG"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((j.currentOpt == "50") && (a.currentOpt == "Dual Stream (4:3)"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((j.currentOpt == "60") && (a.currentOpt == "Dual Stream (4:3)"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((j.currentOpt == "50") && (a.currentOpt == "Video Rotation"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((j.currentOpt == "60") && (a.currentOpt == "Video Rotation"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((j.currentOpt == "50") && (a.currentOpt == "Single Stream"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  }
  else if (((j.currentOpt == "60") && (a.currentOpt == "Single Stream"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((j.currentOpt == "50"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((j.currentOpt == "60"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["FD8166"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  if (((a.currentOpt == "50") && (b.currentOpt == "176x144"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "384x216"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "640x360"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "1280x720"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "1360x768"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "1600x904"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "1920x1080"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  e = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  if (((a.currentOpt == "50") && (e.currentOpt == "176x144"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "384x216"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "640x360"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "1280x720"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "1360x768"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "1600x904"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "1920x1080"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  if (((a.currentOpt == "60") && (b.currentOpt == "176x144"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "384x216"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "640x360"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "1280x720"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "1360x768"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "1600x904"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "1920x1080"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  e = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  if (((a.currentOpt == "60") && (e.currentOpt == "176x144"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "384x216"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "640x360"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "1280x720"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "1360x768"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "1600x904"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "1920x1080"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  i.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  i.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  i.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  i.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["FD8167-T"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8167"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["FD8168"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  if (((a.currentOpt == "50") && (b.currentOpt == "176x144"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "384x216"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "640x360"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "1280x720"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "1360x768"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "1600x904"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "1920x1080"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  e = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  if (((a.currentOpt == "50") && (e.currentOpt == "176x144"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "384x216"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "640x360"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "1280x720"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "1360x768"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "1600x904"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "1920x1080"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  h = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  if (((a.currentOpt == "50") && (h.currentOpt == "176x144"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (h.currentOpt == "384x216"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (h.currentOpt == "640x360"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (h.currentOpt == "1280x720"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (h.currentOpt == "1360x768"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "50") && (h.currentOpt == "1600x904"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "50") && (h.currentOpt == "1920x1080"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  if (((a.currentOpt == "60") && (b.currentOpt == "176x144"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "384x216"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "640x360"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "1280x720"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "1360x768"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "1600x904"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "1920x1080"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  e = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  if (((a.currentOpt == "60") && (e.currentOpt == "176x144"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "384x216"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "640x360"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "1280x720"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "1360x768"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "1600x904"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "1920x1080"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  h = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  if (((a.currentOpt == "60") && (h.currentOpt == "176x144"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (h.currentOpt == "384x216"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (h.currentOpt == "640x360"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (h.currentOpt == "1280x720"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (h.currentOpt == "1360x768"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "60") && (h.currentOpt == "1600x904"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "60") && (h.currentOpt == "1920x1080"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  n.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  n.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["FD8169"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["FD816B-HF2"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD816B-HT"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD816C-HF2"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD816C-HSF2"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8171"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "3-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["%s","176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["%s","176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["%s","176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["%s","176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["%s","176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["%s","176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((f.currentOpt == "50") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((f.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  u = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  v = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  u = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  v = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["FD8173-H"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "3-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  }
  else if (((e.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((e.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((e.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["FD8181"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((e.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((e.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["FD8182-F2"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8182-T"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8184-F2"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","1920x1536","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","1920x1536","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "60") && (b.currentOpt == "176x144") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (b.currentOpt == "320x240") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (b.currentOpt == "640x480") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (b.currentOpt == "800x600") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (b.currentOpt == "1280x960") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (b.currentOpt == "1600x1200") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (b.currentOpt == "2048x1536") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (b.currentOpt == "2560x1920") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "60") && (c.currentOpt == "176x144") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (c.currentOpt == "320x240") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (c.currentOpt == "640x480") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (c.currentOpt == "800x600") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (c.currentOpt == "1280x960") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (c.currentOpt == "1600x1200") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (c.currentOpt == "2048x1536") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (c.currentOpt == "2560x1920") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "60") && (d.currentOpt == "176x144") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (d.currentOpt == "320x240") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (d.currentOpt == "640x480") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (d.currentOpt == "800x600") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (d.currentOpt == "1280x960") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (b.currentOpt == "176x144") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (b.currentOpt == "320x240") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (b.currentOpt == "640x480") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (b.currentOpt == "800x600") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (b.currentOpt == "1280x960") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (b.currentOpt == "1600x1200") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (b.currentOpt == "2048x1536") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (b.currentOpt == "2560x1920") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (c.currentOpt == "176x144") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (c.currentOpt == "320x240") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (c.currentOpt == "640x480") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (c.currentOpt == "800x600") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (c.currentOpt == "1280x960") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (c.currentOpt == "1600x1200") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (c.currentOpt == "2048x1536") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (c.currentOpt == "2560x1920") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (d.currentOpt == "176x144") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (d.currentOpt == "320x240") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (d.currentOpt == "640x480") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (d.currentOpt == "800x600") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (d.currentOpt == "1280x960") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "60") && (a.currentOpt == "1080P FULL HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((e.currentOpt == "50") && (a.currentOpt == "1080P FULL HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((e.currentOpt == "50"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  n = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  n.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  o = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((e.currentOpt == "60"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  n = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  n.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  o = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8335"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8338-HV"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8355"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Dual Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_network_http_s1_access_name");
  b.opts = ["%s"];
  c = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_accessname");
  c.opts = ["%s"];
  d = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_always_enable");
  d.opts = ["0","1"];
  e = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_audio_port");
  e.opts = ["%s"];
  f = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_group_address");
  f.opts = ["%s"];
  g = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_time_to_live");
  g.opts = ["%s"];
  h = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_video_port");
  h.opts = ["%s"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  i.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  j.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  k.opts = ["H.264","JPEG"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  l.opts = ["H.264","JPEG"];
  }
  else if (((a.currentOpt == "Video Rotation"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_network_http_s1_access_name");
  b.opts = ["%s"];
  c = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_accessname");
  c.opts = ["%s"];
  d = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_always_enable");
  d.opts = ["0","1"];
  e = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_audio_port");
  e.opts = ["%s"];
  f = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_group_address");
  f.opts = ["%s"];
  g = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_time_to_live");
  g.opts = ["%s"];
  h = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_video_port");
  h.opts = ["%s"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  i.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  k.opts = ["H.264"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  l.opts = ["H.264"];
  }
  else if (((a.currentOpt == "Single Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_network_http_s1_access_name");
  b.opts = [];
  c = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_accessname");
  c.opts = [];
  d = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_always_enable");
  d.opts = [];
  e = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_audio_port");
  e.opts = [];
  f = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_group_address");
  f.opts = [];
  g = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_time_to_live");
  g.opts = [];
  h = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_video_port");
  h.opts = [];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  i.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  j.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  k.opts = ["H.264","JPEG"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  l.opts = [];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((m.currentOpt == "50") && (a.currentOpt == "Dual Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((m.currentOpt == "60") && (a.currentOpt == "Dual Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((m.currentOpt == "50") && (a.currentOpt == "Video Rotation"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((m.currentOpt == "60") && (a.currentOpt == "Video Rotation"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((m.currentOpt == "50") && (a.currentOpt == "Single Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  }
  else if (((m.currentOpt == "60") && (a.currentOpt == "Single Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((m.currentOpt == "50"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  r.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/500","1/250","1/210","1/180","1/150","1/100","1/50","1/25","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  s.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/500","1/250","1/210","1/180","1/150","1/100","1/50","1/25","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  t.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/500"];
  }
  else if (((m.currentOpt == "60"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  r.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/480","1/240","1/210","1/180","1/150","1/120","1/60","1/30","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  s.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/480","1/240","1/210","1/180","1/150","1/120","1/60","1/30","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  t.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/480"];
  }
  };

    rt["FD8362"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  r = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  if (((a.currentOpt == "60") && (r.currentOpt == "1920x1080"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "1600x904"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "1360x768"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "1280x720"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "640x360"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "384x216"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "176x144"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  s = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  if (((a.currentOpt == "60") && (s.currentOpt == "1920x1080"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "1600x904"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "1360x768"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "1280x720"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "640x360"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "384x216"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "176x144"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  t = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  if (((a.currentOpt == "60") && (t.currentOpt == "1920x1080"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "1600x904"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "1360x768"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "1280x720"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "640x360"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "384x216"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "176x144"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  u = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  if (((a.currentOpt == "60") && (u.currentOpt == "1920x1080"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "1600x904"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "1360x768"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "1280x720"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "640x360"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "384x216"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "176x144"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["FD8363"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  r = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  if (((a.currentOpt == "60") && (r.currentOpt == "1920x1080"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "1600x904"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "1360x768"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "1280x720"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "640x360"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "384x216"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "176x144"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  s = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  if (((a.currentOpt == "60") && (s.currentOpt == "1920x1080"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "1600x904"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "1360x768"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "1280x720"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "640x360"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "384x216"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "176x144"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  t = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  if (((a.currentOpt == "60") && (t.currentOpt == "1920x1080"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "1600x904"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "1360x768"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "1280x720"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "640x360"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "384x216"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "176x144"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  u = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  if (((a.currentOpt == "60") && (u.currentOpt == "1920x1080"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "1600x904"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "1360x768"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "1280x720"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "640x360"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "384x216"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "176x144"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["FD8365"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Dual Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_network_http_s1_access_name");
  b.opts = ["%s"];
  c = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_accessname");
  c.opts = ["%s"];
  d = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_always_enable");
  d.opts = ["0","1"];
  e = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_audio_port");
  e.opts = ["%s"];
  f = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_group_address");
  f.opts = ["%s"];
  g = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_time_to_live");
  g.opts = ["%s"];
  h = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_video_port");
  h.opts = ["%s"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  i.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  j.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  k.opts = ["H.264","JPEG"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  l.opts = ["H.264","JPEG"];
  }
  else if (((a.currentOpt == "Video Rotation"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_network_http_s1_access_name");
  b.opts = ["%s"];
  c = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_accessname");
  c.opts = ["%s"];
  d = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_always_enable");
  d.opts = ["0","1"];
  e = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_audio_port");
  e.opts = ["%s"];
  f = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_group_address");
  f.opts = ["%s"];
  g = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_time_to_live");
  g.opts = ["%s"];
  h = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_video_port");
  h.opts = ["%s"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  i.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  j.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  k.opts = ["H.264"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  l.opts = ["H.264"];
  }
  else if (((a.currentOpt == "Single Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_network_http_s1_access_name");
  b.opts = [];
  c = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_accessname");
  c.opts = [];
  d = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_always_enable");
  d.opts = [];
  e = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_audio_port");
  e.opts = [];
  f = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_group_address");
  f.opts = [];
  g = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_time_to_live");
  g.opts = [];
  h = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_video_port");
  h.opts = [];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  i.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M","18M","20M","24M","28M","32M"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  j.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M","18M","20M","24M","28M","32M"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  k.opts = ["H.264","JPEG"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  l.opts = [];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((m.currentOpt == "50") && (a.currentOpt == "Dual Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((m.currentOpt == "60") && (a.currentOpt == "Dual Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((m.currentOpt == "50") && (a.currentOpt == "Video Rotation"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((m.currentOpt == "60") && (a.currentOpt == "Video Rotation"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((m.currentOpt == "50") && (a.currentOpt == "Single Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  }
  else if (((m.currentOpt == "60") && (a.currentOpt == "Single Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((m.currentOpt == "50"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  r.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/500","1/250","1/210","1/180","1/150","1/100","1/50","1/25","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  s.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/500","1/250","1/210","1/180","1/150","1/100","1/50","1/25","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  t.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/500"];
  }
  else if (((m.currentOpt == "60"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  r.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/480","1/240","1/210","1/180","1/150","1/120","1/60","1/30","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  s.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/480","1/240","1/210","1/180","1/150","1/120","1/60","1/30","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  t.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/480"];
  }
  };

    rt["FD8367-TV"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8367-V"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["FD836B-EHTV"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD836B-EHVF2"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD836B-HTV"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD836B-HVF2"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8371"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "3-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["%s","176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["%s","176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["%s","176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["%s","176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["%s","176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["%s","176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((f.currentOpt == "50") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((f.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  u = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  v = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  u = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  v = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["FD8372"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["%s","176x144","320x240","640x480","800x600","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["%s","176x144","320x240","640x480","800x600","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["%s","176x144","320x240","640x480","800x600","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "3-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["%s","176x144","320x240","640x480","800x600","1600x1200","2048x1536"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["%s","176x144","320x240","640x480","800x600","1600x1200","2048x1536"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["%s","176x144","320x240","640x480","800x600","1600x1200","2048x1536"];
  }
  else if (((a.currentOpt == "2-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["%s","176x144","320x240","640x480","800x600","1600x1200"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["%s","176x144","320x240","640x480","800x600","1600x1200"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["%s","176x144","320x240","640x480","800x600","1600x1200"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["%s","176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["%s","176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["%s","176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }
  else if (((a.currentOpt == "720P HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["%s","176x144","384x216","640x360","1280x720"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["%s","176x144","384x216","640x360","1280x720"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["%s","176x144","384x216","640x360","1280x720"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (e.currentOpt == "50"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","13"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","13"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","13"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (e.currentOpt == "60"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","13"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","13"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","13"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10"];
  }
  else if (((a.currentOpt == "3-Megapixel (4:3)") && (e.currentOpt == "50"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20"];
  }
  else if (((a.currentOpt == "3-Megapixel (4:3)") && (e.currentOpt == "60"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20"];
  }
  else if (((a.currentOpt == "2-Megapixel (4:3)") && (e.currentOpt == "50"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "2-Megapixel (4:3)") && (e.currentOpt == "60"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "50"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "60"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "720P HD (16:9)") && (e.currentOpt == "50"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "720P HD (16:9)") && (e.currentOpt == "60"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((e.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((e.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  w.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  w.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((n.currentOpt == "1"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "2"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "3"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(n.currentOpt) > Number("3")))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8373"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "0"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  }
  else if (((a.currentOpt == "1"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  }
  else if (((e.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8381-EV"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (a.currentOpt == "5-Megapixel"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "5-Megapixel"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "50") && (a.currentOpt == "1080P"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "1080P"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((e.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((e.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["FD8382-ETV"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8382-EVF2"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8382-TV"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD8382-VF2"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FD9171-HT"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  r = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  s = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((r.currentOpt == "JPEG") && (s.currentOpt == "1600x1200"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (s.currentOpt == "2048x1536"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "320x240"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "640x480"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "800x600"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "1280x960"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "320x240"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "640x480"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "800x600"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "1280x960"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  u = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  v = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((u.currentOpt == "JPEG") && (v.currentOpt == "1600x1200"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (v.currentOpt == "2048x1536"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "320x240"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "640x480"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "800x600"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "1280x960"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "320x240"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "640x480"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "800x600"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "1280x960"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  x = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  y = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((x.currentOpt == "JPEG") && (y.currentOpt == "1600x1200"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (y.currentOpt == "2048x1536"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "320x240"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "640x480"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "800x600"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "1280x960"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "320x240"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "640x480"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "800x600"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "1280x960"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  };

    rt["FD9371-EHTV"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  r = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  s = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((r.currentOpt == "JPEG") && (s.currentOpt == "1600x1200"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (s.currentOpt == "2048x1536"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "320x240"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "640x480"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "800x600"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "1280x960"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "320x240"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "640x480"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "800x600"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "1280x960"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  u = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  v = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((u.currentOpt == "JPEG") && (v.currentOpt == "1600x1200"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (v.currentOpt == "2048x1536"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "320x240"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "640x480"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "800x600"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "1280x960"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "320x240"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "640x480"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "800x600"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "1280x960"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  x = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  y = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((x.currentOpt == "JPEG") && (y.currentOpt == "1600x1200"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (y.currentOpt == "2048x1536"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "320x240"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "640x480"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "800x600"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "1280x960"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "320x240"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "640x480"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "800x600"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "1280x960"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  };

    rt["FD9371-HTV"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  r = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  s = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((r.currentOpt == "JPEG") && (s.currentOpt == "1600x1200"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (s.currentOpt == "2048x1536"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "320x240"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "640x480"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "800x600"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "1280x960"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "320x240"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "640x480"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "800x600"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "1280x960"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  u = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  v = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((u.currentOpt == "JPEG") && (v.currentOpt == "1600x1200"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (v.currentOpt == "2048x1536"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "320x240"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "640x480"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "800x600"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "1280x960"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "320x240"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "640x480"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "800x600"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "1280x960"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  x = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  y = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((x.currentOpt == "JPEG") && (y.currentOpt == "1600x1200"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (y.currentOpt == "2048x1536"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "320x240"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "640x480"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "800x600"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "1280x960"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "320x240"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "640x480"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "800x600"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "1280x960"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  };

    rt["FE8171"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p;
  a = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((a.currentOpt == "1"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((a.currentOpt == "2"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((a.currentOpt == "3"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(a.currentOpt) > Number("3")))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  d.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  d.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  d.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  d.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  f.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  f.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  f.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  f.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  h.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  h.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  o = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((o.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(o.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FE8172"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Fisheye mode"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  }
  else if (((a.currentOpt == "1080P Full HD"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (a.currentOpt == "Fisheye mode"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((e.currentOpt == "50") && (a.currentOpt == "1080P Full HD"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "Fisheye mode"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "1080P Full HD"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((o.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(o.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((e.currentOpt == "50"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  w = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  w.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  x = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  x.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  y = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  y.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((e.currentOpt == "60"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  w = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  w.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  x = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  x.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  y = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  y.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["FE8173"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((n.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(n.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FE8174"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Fisheye mode"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  }
  else if (((a.currentOpt == "1080P Full HD"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (a.currentOpt == "Fisheye mode"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "Fisheye mode"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (a.currentOpt == "1080P Full HD"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "1080P Full HD"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((n.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(n.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FE8180"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Fisheye mode"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (a.currentOpt == "Fisheye mode"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "Fisheye mode"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((e.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((e.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["FE8181"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Fisheye mode"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (a.currentOpt == "Fisheye mode"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "Fisheye mode"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((n.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(n.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((e.currentOpt == "50"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  v = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  w = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  w.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  x = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  x.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((e.currentOpt == "60"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  v = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  w = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  w.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  x = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  x.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["FE8181V"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Fisheye mode"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (a.currentOpt == "Fisheye mode"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "Fisheye mode"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((n.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(n.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((e.currentOpt == "50"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  v = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  w = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  w.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  x = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  x.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((e.currentOpt == "60"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  v = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  w = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  w.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  x = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  x.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["FE8182"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FE8191-V"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FE8191"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u;
  a = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((a.currentOpt == "1"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  b.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((a.currentOpt == "2"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  b.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((a.currentOpt == "3"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  b.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(a.currentOpt) > Number("3")))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  b.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  d.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  d.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  d.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  d.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  f.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  f.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  f.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  f.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  h.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  h.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  o = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((o.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(o.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  q = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((q.currentOpt == "50"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  u = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((q.currentOpt == "60"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  u = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["FE8391-V"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["FE8X91"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h;
  a = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((a.currentOpt == "1"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((a.currentOpt == "2"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((a.currentOpt == "3"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(a.currentOpt) > Number("3")))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  d.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  d.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  d.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  d.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  f.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  f.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  f.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  f.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  h.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  h.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB8156"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["IB8168"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  if (((a.currentOpt == "50") && (b.currentOpt == "176x144"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "384x216"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "640x360"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "1280x720"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "1360x768"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "1600x904"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "50") && (b.currentOpt == "1920x1080"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  e = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  if (((a.currentOpt == "50") && (e.currentOpt == "176x144"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "384x216"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "640x360"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "1280x720"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "1360x768"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "1600x904"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "50") && (e.currentOpt == "1920x1080"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  h = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  if (((a.currentOpt == "50") && (h.currentOpt == "176x144"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (h.currentOpt == "384x216"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (h.currentOpt == "640x360"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (h.currentOpt == "1280x720"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "50") && (h.currentOpt == "1360x768"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "50") && (h.currentOpt == "1600x904"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "50") && (h.currentOpt == "1920x1080"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  if (((a.currentOpt == "60") && (b.currentOpt == "176x144"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "384x216"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "640x360"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "1280x720"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "1360x768"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "1600x904"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "60") && (b.currentOpt == "1920x1080"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  e = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  if (((a.currentOpt == "60") && (e.currentOpt == "176x144"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "384x216"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "640x360"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "1280x720"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "1360x768"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "1600x904"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "60") && (e.currentOpt == "1920x1080"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  h = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  if (((a.currentOpt == "60") && (h.currentOpt == "176x144"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (h.currentOpt == "384x216"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (h.currentOpt == "640x360"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (h.currentOpt == "1280x720"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (h.currentOpt == "1360x768"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "60") && (h.currentOpt == "1600x904"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "60") && (h.currentOpt == "1920x1080"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  n.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  n.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["IB8338-H"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB8338-HR"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB8354"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["IB8367-R"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["IB8367-RT"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["IB8367-T"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["IB8367"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["IB8369"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["IB836B-EHF3"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB836B-EHT"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB836B-HF3"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB836B-HRF3"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB836B-HRT"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB836B-HT"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB8373-EH"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "3-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  }
  else if (((e.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((e.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((e.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["IB8373"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "0"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  }
  else if (((a.currentOpt == "1"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (a.currentOpt == "0"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "0"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  }
  else if (((e.currentOpt == "50") && (a.currentOpt == "1"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "1"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB8381"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((e.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((e.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["IB8382-EF3"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB8382-ET"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB8382-F3"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB8382-RF3"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB8382-RT"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB8382-T"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB9371-EHT"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  r = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  s = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((r.currentOpt == "JPEG") && (s.currentOpt == "1600x1200"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (s.currentOpt == "2048x1536"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "320x240"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "640x480"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "800x600"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "1280x960"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "320x240"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "640x480"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "800x600"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "1280x960"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  u = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  v = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((u.currentOpt == "JPEG") && (v.currentOpt == "1600x1200"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (v.currentOpt == "2048x1536"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "320x240"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "640x480"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "800x600"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "1280x960"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "320x240"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "640x480"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "800x600"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "1280x960"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  x = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  y = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((x.currentOpt == "JPEG") && (y.currentOpt == "1600x1200"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (y.currentOpt == "2048x1536"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "320x240"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "640x480"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "800x600"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "1280x960"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "320x240"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "640x480"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "800x600"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "1280x960"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  };

    rt["IB9371-HRT"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  n = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  n.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  n = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  n.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IB9371-HT"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  r = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  s = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((r.currentOpt == "JPEG") && (s.currentOpt == "1600x1200"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (s.currentOpt == "2048x1536"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "320x240"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "640x480"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "800x600"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "1280x960"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "320x240"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "640x480"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "800x600"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "1280x960"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  u = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  v = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((u.currentOpt == "JPEG") && (v.currentOpt == "1600x1200"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (v.currentOpt == "2048x1536"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "320x240"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "640x480"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "800x600"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "1280x960"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "320x240"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "640x480"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "800x600"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "1280x960"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  x = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  y = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((x.currentOpt == "JPEG") && (y.currentOpt == "1600x1200"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (y.currentOpt == "2048x1536"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "320x240"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "640x480"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "800x600"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "1280x960"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "320x240"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "640x480"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "800x600"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "1280x960"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  };

    rt["IB9381-HT"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z, A, B, C, D, E, F, G, H;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h265_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h265_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h265_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h265_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  n = devicePackCtrl.GetUISet(camID, "uid_s3_h265_max_frame_rate");
  if (((n.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h265_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h265_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h265_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(n.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h265_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  w.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  w.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  x.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  x.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  y.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  y.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  z.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  z.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  A = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (A.currentOpt == "JPEG") && (b.currentOpt == "2048x1536"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (A.currentOpt == "JPEG") && (b.currentOpt == "2560x1920"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "320x240"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "640x480"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "800x600"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "1280x960"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "1600x1200"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "320x240"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "640x480"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "800x600"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "1280x960"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "1600x1200"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  C = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (C.currentOpt == "JPEG") && (c.currentOpt == "2048x1536"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (C.currentOpt == "JPEG") && (c.currentOpt == "2560x1920"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "320x240"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "640x480"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "800x600"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "1280x960"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "1600x1200"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "320x240"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "640x480"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "800x600"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "1280x960"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "1600x1200"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  E = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (E.currentOpt == "JPEG") && (d.currentOpt == "2048x1536"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (E.currentOpt == "JPEG") && (d.currentOpt == "2560x1920"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "320x240"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "640x480"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "800x600"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "1280x960"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "1600x1200"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "320x240"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "640x480"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "800x600"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "1280x960"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "1600x1200"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  G = devicePackCtrl.GetUISet(camID, "uid_s3_video_codec");
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (G.currentOpt == "JPEG") && (e.currentOpt == "2048x1536"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (G.currentOpt == "JPEG") && (e.currentOpt == "2560x1920"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "320x240"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "640x480"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "800x600"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "1280x960"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "1600x1200"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "320x240"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "640x480"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "800x600"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "1280x960"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "1600x1200"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  A = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "1080P FULL HD (16:9)") && (A.currentOpt == "JPEG") && (b.currentOpt == "1600x904"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (A.currentOpt == "JPEG") && (b.currentOpt == "1920x1080"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "384x216"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "640x360"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "1280x720"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "1360x768"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "384x216"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "640x360"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "1280x720"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "1360x768"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  C = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "1080P FULL HD (16:9)") && (C.currentOpt == "JPEG") && (c.currentOpt == "1600x904"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (C.currentOpt == "JPEG") && (c.currentOpt == "1920x1080"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "384x216"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "640x360"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "1280x720"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "1360x768"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "384x216"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "640x360"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "1280x720"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "1360x768"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  E = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "1080P FULL HD (16:9)") && (E.currentOpt == "JPEG") && (d.currentOpt == "1600x904"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (E.currentOpt == "JPEG") && (d.currentOpt == "1920x1080"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "384x216"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "640x360"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "1280x720"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "1360x768"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "384x216"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "640x360"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "1280x720"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "1360x768"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  G = devicePackCtrl.GetUISet(camID, "uid_s3_video_codec");
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "1080P FULL HD (16:9)") && (G.currentOpt == "JPEG") && (e.currentOpt == "1600x904"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (G.currentOpt == "JPEG") && (e.currentOpt == "1920x1080"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "384x216"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "640x360"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "1280x720"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "1360x768"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "384x216"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "640x360"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "1280x720"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "1360x768"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55"];
  }
  };

    rt["IP7330"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  f.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  g = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  g.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  h = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  f.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  g = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  g.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  h = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP7361"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8130"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8131"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8132"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  i.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  i.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  i.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  i.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8133"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/25","1/15"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/25","1/15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/30","1/15"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/30","1/15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8133W"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/25","1/15"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/25","1/15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/30","1/15"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/30","1/15"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8151"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((o.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(o.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((r.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((r.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((r.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(r.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  w.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  w.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  x.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  x.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  y.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  y.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((p.currentOpt == "1"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  z.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((p.currentOpt == "2"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((p.currentOpt == "3"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(p.currentOpt) > Number("3")))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  z.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8152"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8155"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Dual Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_network_http_s1_access_name");
  b.opts = ["%s"];
  c = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_accessname");
  c.opts = ["%s"];
  d = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_always_enable");
  d.opts = ["0","1"];
  e = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_audio_port");
  e.opts = ["%s"];
  f = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_group_address");
  f.opts = ["%s"];
  g = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_time_to_live");
  g.opts = ["%s"];
  h = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_video_port");
  h.opts = ["%s"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  i.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  j.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  k.opts = ["H.264","JPEG"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  l.opts = ["H.264","JPEG"];
  }
  else if (((a.currentOpt == "Video Rotation"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_network_http_s1_access_name");
  b.opts = ["%s"];
  c = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_accessname");
  c.opts = ["%s"];
  d = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_always_enable");
  d.opts = ["0","1"];
  e = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_audio_port");
  e.opts = ["%s"];
  f = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_group_address");
  f.opts = ["%s"];
  g = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_time_to_live");
  g.opts = ["%s"];
  h = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_video_port");
  h.opts = ["%s"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  i.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  j.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  k.opts = ["H.264"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  l.opts = ["H.264"];
  }
  else if (((a.currentOpt == "Single Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_network_http_s1_access_name");
  b.opts = [];
  c = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_accessname");
  c.opts = [];
  d = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_always_enable");
  d.opts = [];
  e = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_audio_port");
  e.opts = [];
  f = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_group_address");
  f.opts = [];
  g = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_time_to_live");
  g.opts = [];
  h = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_video_port");
  h.opts = [];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  i.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  j.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  k.opts = ["H.264","JPEG"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  l.opts = [];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((m.currentOpt == "50") && (a.currentOpt == "Dual Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((m.currentOpt == "60") && (a.currentOpt == "Dual Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((m.currentOpt == "50") && (a.currentOpt == "Video Rotation"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((m.currentOpt == "60") && (a.currentOpt == "Video Rotation"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((m.currentOpt == "50") && (a.currentOpt == "Single Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  }
  else if (((m.currentOpt == "60") && (a.currentOpt == "Single Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((m.currentOpt == "50"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  r.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/500","1/250","1/210","1/180","1/150","1/100","1/50","1/25","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  s.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/500","1/250","1/210","1/180","1/150","1/100","1/50","1/25","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  t.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/500"];
  }
  else if (((m.currentOpt == "60"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  r.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/480","1/240","1/210","1/180","1/150","1/120","1/60","1/30","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  s.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/480","1/240","1/210","1/180","1/150","1/120","1/60","1/30","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  t.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/480"];
  }
  };

    rt["IP8161"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "0"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200"];
  }
  else if (((a.currentOpt == "1"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600"];
  }
  else if (((a.currentOpt == "2"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["320x176","640x360","1280x720"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["320x176","640x360","1280x720"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["320x176","640x360","1280x720"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["320x176","640x360","1280x720"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((f.currentOpt == "50") && (a.currentOpt == "0"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "0"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15"];
  }
  else if (((f.currentOpt == "50") && (a.currentOpt == "1"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((f.currentOpt == "50") && (a.currentOpt == "2"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "2"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  w.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  w.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((o.currentOpt == "1"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "2"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "3"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(o.currentOpt) > Number("3")))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((p.currentOpt == "1"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((p.currentOpt == "2"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((p.currentOpt == "3"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(p.currentOpt) > Number("3")))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((r.currentOpt == "1"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((r.currentOpt == "2"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((r.currentOpt == "3"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(r.currentOpt) > Number("3")))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8162"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  s = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  if (((a.currentOpt == "60") && (s.currentOpt == "1920x1080"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "1600x904"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "1360x768"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "1280x720"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "640x360"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "384x216"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "176x144"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  t = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  if (((a.currentOpt == "60") && (t.currentOpt == "1920x1080"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "1600x904"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "1360x768"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "1280x720"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "640x360"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "384x216"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (t.currentOpt == "176x144"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  u = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  if (((a.currentOpt == "60") && (u.currentOpt == "1920x1080"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "1600x904"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "1360x768"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "1280x720"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "640x360"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "384x216"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (u.currentOpt == "176x144"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  v = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  if (((a.currentOpt == "60") && (v.currentOpt == "1920x1080"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (v.currentOpt == "1600x904"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (v.currentOpt == "1360x768"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (v.currentOpt == "1280x720"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (v.currentOpt == "640x360"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (v.currentOpt == "384x216"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (v.currentOpt == "176x144"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["IP8165"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Dual Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_network_http_s1_access_name");
  b.opts = ["%s"];
  c = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_accessname");
  c.opts = ["%s"];
  d = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_always_enable");
  d.opts = ["0","1"];
  e = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_audio_port");
  e.opts = ["%s"];
  f = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_group_address");
  f.opts = ["%s"];
  g = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_time_to_live");
  g.opts = ["%s"];
  h = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_video_port");
  h.opts = ["%s"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  i.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  j.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  k.opts = ["H.264","JPEG"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  l.opts = ["H.264","JPEG"];
  }
  else if (((a.currentOpt == "Video Rotation"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_network_http_s1_access_name");
  b.opts = ["%s"];
  c = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_accessname");
  c.opts = ["%s"];
  d = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_always_enable");
  d.opts = ["0","1"];
  e = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_audio_port");
  e.opts = ["%s"];
  f = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_group_address");
  f.opts = ["%s"];
  g = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_time_to_live");
  g.opts = ["%s"];
  h = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_video_port");
  h.opts = ["%s"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  i.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  j.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  k.opts = ["H.264"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  l.opts = ["H.264"];
  }
  else if (((a.currentOpt == "Single Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_network_http_s1_access_name");
  b.opts = [];
  c = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_accessname");
  c.opts = [];
  d = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_always_enable");
  d.opts = [];
  e = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_audio_port");
  e.opts = [];
  f = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_group_address");
  f.opts = [];
  g = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_time_to_live");
  g.opts = [];
  h = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_video_port");
  h.opts = [];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  i.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M","18M","20M","24M","28M","32M"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  j.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M","18M","20M","24M","28M","32M"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  k.opts = ["H.264","JPEG"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  l.opts = [];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((m.currentOpt == "50") && (a.currentOpt == "Dual Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((m.currentOpt == "60") && (a.currentOpt == "Dual Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((m.currentOpt == "50") && (a.currentOpt == "Video Rotation"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((m.currentOpt == "60") && (a.currentOpt == "Video Rotation"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((m.currentOpt == "50") && (a.currentOpt == "Single Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  }
  else if (((m.currentOpt == "60") && (a.currentOpt == "Single Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((m.currentOpt == "50"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  r.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/500","1/250","1/210","1/180","1/150","1/100","1/50","1/25","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  s.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/500","1/250","1/210","1/180","1/150","1/100","1/50","1/25","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  t.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/500"];
  }
  else if (((m.currentOpt == "60"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  r.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/480","1/240","1/210","1/180","1/150","1/120","1/60","1/30","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  s.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/480","1/240","1/210","1/180","1/150","1/120","1/60","1/30","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  t.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/480"];
  }
  };

    rt["IP816A-HP"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Dual Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  c.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  d.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  e.opts = ["H.264","JPEG"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  f.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_h264_constant_bitrate");
  g.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_constant_bitrate");
  h.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  i.opts = ["H.264","JPEG"];
  }
  else if (((a.currentOpt == "Video Rotation"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  c.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  d.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  e.opts = ["H.264","JPEG"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  f.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_h264_constant_bitrate");
  g.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M","20M","24M","28M","32M"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_constant_bitrate");
  h.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M","20M","24M","28M","32M"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  i.opts = ["H.264","JPEG"];
  }
  else if (((a.currentOpt == "Single Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  c.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M","18M","20M","24M","28M","32M"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  d.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M","18M","20M","24M","28M","32M"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  e.opts = ["H.264","JPEG"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((j.currentOpt == "50") && (a.currentOpt == "Dual Stream"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((j.currentOpt == "60") && (a.currentOpt == "Dual Stream"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((j.currentOpt == "50") && (a.currentOpt == "Video Rotation"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((j.currentOpt == "60") && (a.currentOpt == "Video Rotation"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((j.currentOpt == "50") && (a.currentOpt == "Single Stream"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  }
  else if (((j.currentOpt == "60") && (a.currentOpt == "Single Stream"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((j.currentOpt == "50"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((j.currentOpt == "60"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["IP8172"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "3-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1600x1200","2048x1536"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1600x1200","2048x1536"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1600x1200","2048x1536"];
  }
  else if (((a.currentOpt == "2-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1600x1200"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  e.opts = ["H.264","MPEG-4","JPEG"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1600x1200"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  f.opts = ["H.264","MPEG-4","JPEG"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1600x1200"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  g.opts = ["H.264","MPEG-4","JPEG"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  e.opts = ["H.264","MPEG-4","JPEG"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  f.opts = ["H.264","MPEG-4","JPEG"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  g.opts = ["H.264","MPEG-4","JPEG"];
  }
  else if (((a.currentOpt == "720P HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  e.opts = ["H.264","MPEG-4","JPEG"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  f.opts = ["H.264","MPEG-4","JPEG"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  g.opts = ["H.264","MPEG-4","JPEG"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((h.currentOpt == "50") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","13"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","13"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","13"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10"];
  }
  else if (((h.currentOpt == "60") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","13"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","13"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","13"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10"];
  }
  else if (((h.currentOpt == "50") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20"];
  }
  else if (((h.currentOpt == "60") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20"];
  }
  else if (((h.currentOpt == "50") && (a.currentOpt == "2-Megapixel (4:3)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((h.currentOpt == "60") && (a.currentOpt == "2-Megapixel (4:3)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((h.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((h.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((h.currentOpt == "50") && (a.currentOpt == "720P HD (16:9)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  }
  else if (((h.currentOpt == "60") && (a.currentOpt == "720P HD (16:9)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((n.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(n.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((o.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(o.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((q.currentOpt == "1"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  w.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((q.currentOpt == "2"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((q.currentOpt == "3"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(q.currentOpt) > Number("3")))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  w.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8173"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "3-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["%s","176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["%s","176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["%s","176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["%s","176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  n = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "3-Megapixel (4:3)"))) {
  e = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20"];
  }
  else if (((n.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  e = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((n.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  e = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  n = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((n.currentOpt == "50"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  v = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  w = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  w.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  x = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  x.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((n.currentOpt == "60"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  v = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  w = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  w.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  x = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  x.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["IP8330"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  k = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8331"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  h.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  i.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  i.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  i.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  i.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8332"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  n = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((n.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(n.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  q = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((q.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((q.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((q.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(q.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  w.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  w.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  x.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  x.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  o = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((o.currentOpt == "1"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "2"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "3"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(o.currentOpt) > Number("3")))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8335"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8336"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8337"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8355"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Dual Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  b.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  d.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  f.opts = ["H.264","JPEG"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  g.opts = ["H.264","JPEG"];
  }
  else if (((a.currentOpt == "Video Rotation"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  b.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  d.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  f.opts = ["H.264"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  g.opts = ["H.264"];
  }
  else if (((a.currentOpt == "Single Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  b.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  d.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  f.opts = ["H.264","JPEG"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((h.currentOpt == "50") && (a.currentOpt == "Dual Stream"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((h.currentOpt == "60") && (a.currentOpt == "Dual Stream"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((h.currentOpt == "50") && (a.currentOpt == "Video Rotation"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((h.currentOpt == "60") && (a.currentOpt == "Video Rotation"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((h.currentOpt == "50") && (a.currentOpt == "Single Stream"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  }
  else if (((h.currentOpt == "60") && (a.currentOpt == "Single Stream"))) {
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((h.currentOpt == "50"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  n = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  n.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((h.currentOpt == "60"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  n = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  n.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["IP8361"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "0"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200"];
  }
  else if (((a.currentOpt == "1"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600"];
  }
  else if (((a.currentOpt == "2"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["320x176","640x360","1280x720"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["320x176","640x360","1280x720"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["320x176","640x360","1280x720"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["320x176","640x360","1280x720"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((f.currentOpt == "50") && (a.currentOpt == "0"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "0"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15"];
  }
  else if (((f.currentOpt == "50") && (a.currentOpt == "1"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((f.currentOpt == "50") && (a.currentOpt == "2"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "2"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  w.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  w.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((o.currentOpt == "1"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "2"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "3"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(o.currentOpt) > Number("3")))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  x.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((p.currentOpt == "1"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((p.currentOpt == "2"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((p.currentOpt == "3"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(p.currentOpt) > Number("3")))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  y.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((r.currentOpt == "1"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((r.currentOpt == "2"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((r.currentOpt == "3"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(r.currentOpt) > Number("3")))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP8362"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["IP8364"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["IP8365"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Dual Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_network_http_s1_access_name");
  b.opts = ["%s"];
  c = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_accessname");
  c.opts = ["%s"];
  d = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_always_enable");
  d.opts = ["0","1"];
  e = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_audio_port");
  e.opts = ["%s"];
  f = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_group_address");
  f.opts = ["%s"];
  g = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_time_to_live");
  g.opts = ["%s"];
  h = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_video_port");
  h.opts = ["%s"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  i.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  j.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  k.opts = ["H.264","JPEG"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  l.opts = ["H.264","JPEG"];
  }
  else if (((a.currentOpt == "Video Rotation"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_network_http_s1_access_name");
  b.opts = ["%s"];
  c = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_accessname");
  c.opts = ["%s"];
  d = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_always_enable");
  d.opts = ["0","1"];
  e = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_audio_port");
  e.opts = ["%s"];
  f = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_group_address");
  f.opts = ["%s"];
  g = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_time_to_live");
  g.opts = ["%s"];
  h = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_video_port");
  h.opts = ["%s"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  i.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  k.opts = ["H.264"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  l.opts = ["H.264"];
  }
  else if (((a.currentOpt == "Single Stream"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_network_http_s1_access_name");
  b.opts = [];
  c = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_accessname");
  c.opts = [];
  d = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_always_enable");
  d.opts = [];
  e = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_audio_port");
  e.opts = [];
  f = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_group_address");
  f.opts = [];
  g = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_time_to_live");
  g.opts = [];
  h = devicePackCtrl.GetUISet(camID, "uid_network_rtsp_s1_multicast_video_port");
  h.opts = [];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_constant_bitrate");
  i.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M","18M","20M","24M","28M","32M"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_constant_bitrate");
  j.opts = ["%s","20K","30K","40K","50K","64K","128K","256K","512K","768K","1M","2M","3M","4M","6M","8M","10M","12M","14M","16M","18M","20M","24M","28M","32M"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  k.opts = ["H.264","JPEG"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  l.opts = [];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((m.currentOpt == "50") && (a.currentOpt == "Dual Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((m.currentOpt == "60") && (a.currentOpt == "Dual Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((m.currentOpt == "50") && (a.currentOpt == "Video Rotation"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((m.currentOpt == "60") && (a.currentOpt == "Video Rotation"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((m.currentOpt == "50") && (a.currentOpt == "Single Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50"];
  }
  else if (((m.currentOpt == "60") && (a.currentOpt == "Single Stream"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","50","60"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((m.currentOpt == "50"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  r.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/500","1/250","1/210","1/180","1/150","1/100","1/50","1/25","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  s.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/500","1/250","1/210","1/180","1/150","1/100","1/50","1/25","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  t.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/500"];
  }
  else if (((m.currentOpt == "60"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  r.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/480","1/240","1/210","1/180","1/150","1/120","1/60","1/30","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  s.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/480","1/240","1/210","1/180","1/150","1/120","1/60","1/30","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  t.opts = ["1/10000","1/8000","1/4000","1/2000","1/1200","1/1000","1/800","1/480"];
  }
  };

    rt["IP8371"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "3-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["%s","176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["%s","176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["%s","176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960","1600x1200","2048x1536"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["%s","176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["%s","176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["%s","176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((f.currentOpt == "50") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((f.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  u = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  v = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  u = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  v = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["IP8372"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "3-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1600x1200","2048x1536"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1600x1200","2048x1536"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1600x1200","2048x1536"];
  }
  else if (((a.currentOpt == "2-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","320x240","640x480","800x600","1600x1200"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  e.opts = ["H.264","MPEG-4","JPEG"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","320x240","640x480","800x600","1600x1200"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  f.opts = ["H.264","MPEG-4","JPEG"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","320x240","640x480","800x600","1600x1200"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  g.opts = ["H.264","MPEG-4","JPEG"];
  }
  else if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  e.opts = ["H.264","MPEG-4","JPEG"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  f.opts = ["H.264","MPEG-4","JPEG"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  g.opts = ["H.264","MPEG-4","JPEG"];
  }
  else if (((a.currentOpt == "720P HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  e.opts = ["H.264","MPEG-4","JPEG"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  f.opts = ["H.264","MPEG-4","JPEG"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  g.opts = ["H.264","MPEG-4","JPEG"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((h.currentOpt == "50") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","13"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","13"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","13"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10"];
  }
  else if (((h.currentOpt == "60") && (a.currentOpt == "5-Megapixel (4:3)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","13"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","13"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","13"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10"];
  }
  else if (((h.currentOpt == "50") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20"];
  }
  else if (((h.currentOpt == "60") && (a.currentOpt == "3-Megapixel (4:3)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20"];
  }
  else if (((h.currentOpt == "50") && (a.currentOpt == "2-Megapixel (4:3)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((h.currentOpt == "60") && (a.currentOpt == "2-Megapixel (4:3)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((h.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((h.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((h.currentOpt == "50") && (a.currentOpt == "720P HD (16:9)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50"];
  }
  else if (((h.currentOpt == "60") && (a.currentOpt == "720P HD (16:9)"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30","35","40","45","50","55","60"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((n.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(n.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((o.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(o.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  q = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((q.currentOpt == "1"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  w.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((q.currentOpt == "2"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((q.currentOpt == "3"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(q.currentOpt) > Number("3")))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  w.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP9171-HP"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  r = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  s = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((r.currentOpt == "JPEG") && (s.currentOpt == "1600x1200"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (s.currentOpt == "2048x1536"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "320x240"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "640x480"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "800x600"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "60") && (s.currentOpt == "1280x960"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "320x240"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "640x480"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "800x600"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((r.currentOpt == "JPEG") && (a.currentOpt == "50") && (s.currentOpt == "1280x960"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  u = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  v = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((u.currentOpt == "JPEG") && (v.currentOpt == "1600x1200"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (v.currentOpt == "2048x1536"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "320x240"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "640x480"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "800x600"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "60") && (v.currentOpt == "1280x960"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "320x240"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "640x480"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "800x600"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((u.currentOpt == "JPEG") && (a.currentOpt == "50") && (v.currentOpt == "1280x960"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  w.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }

  x = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  y = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((x.currentOpt == "JPEG") && (y.currentOpt == "1600x1200"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (y.currentOpt == "2048x1536"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "320x240"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "640x480"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "800x600"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "60") && (y.currentOpt == "1280x960"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "320x240"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "640x480"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "800x600"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((x.currentOpt == "JPEG") && (a.currentOpt == "50") && (y.currentOpt == "1280x960"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  z.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  };

    rt["IP9171"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["IP9181-H"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z, A, B, C, D, E, F, G, H;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "5-Megapixel (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["320x240","640x480","800x600","1280x960","1600x1200","2048x1536","2560x1920"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h265_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h265_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h265_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h265_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  o.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  p = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  p.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  q = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  q.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  r = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s0_h265_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_h265_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s1_h265_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s1_h265_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s2_h265_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s2_h265_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  n = devicePackCtrl.GetUISet(camID, "uid_s3_h265_max_frame_rate");
  if (((n.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h265_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h265_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h265_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(n.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_h265_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  w.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  w.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  x.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  x.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  y.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  y.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  z.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  z.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  A = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (A.currentOpt == "JPEG") && (b.currentOpt == "2048x1536"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (A.currentOpt == "JPEG") && (b.currentOpt == "2560x1920"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "320x240"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "640x480"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "800x600"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "1280x960"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "1600x1200"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "320x240"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "640x480"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "800x600"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "1280x960"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "1600x1200"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  C = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (C.currentOpt == "JPEG") && (c.currentOpt == "2048x1536"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (C.currentOpt == "JPEG") && (c.currentOpt == "2560x1920"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "320x240"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "640x480"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "800x600"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "1280x960"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "1600x1200"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "320x240"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "640x480"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "800x600"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "1280x960"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "1600x1200"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  E = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (E.currentOpt == "JPEG") && (d.currentOpt == "2048x1536"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (E.currentOpt == "JPEG") && (d.currentOpt == "2560x1920"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "320x240"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "640x480"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "800x600"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "1280x960"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "1600x1200"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "320x240"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "640x480"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "800x600"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "1280x960"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "1600x1200"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  G = devicePackCtrl.GetUISet(camID, "uid_s3_video_codec");
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "5-Megapixel (4:3)") && (G.currentOpt == "JPEG") && (e.currentOpt == "2048x1536"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (G.currentOpt == "JPEG") && (e.currentOpt == "2560x1920"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "320x240"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "640x480"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "800x600"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "1280x960"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "1600x1200"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "320x240"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "640x480"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "800x600"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "1280x960"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "5-Megapixel (4:3)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "1600x1200"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  A = devicePackCtrl.GetUISet(camID, "uid_s0_video_codec");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "1080P FULL HD (16:9)") && (A.currentOpt == "JPEG") && (b.currentOpt == "1600x904"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (A.currentOpt == "JPEG") && (b.currentOpt == "1920x1080"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "384x216"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "640x360"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "1280x720"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (A.currentOpt == "JPEG") && (b.currentOpt == "1360x768"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "384x216"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "640x360"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "1280x720"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (A.currentOpt == "JPEG") && (b.currentOpt == "1360x768"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  B.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  C = devicePackCtrl.GetUISet(camID, "uid_s1_video_codec");
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "1080P FULL HD (16:9)") && (C.currentOpt == "JPEG") && (c.currentOpt == "1600x904"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (C.currentOpt == "JPEG") && (c.currentOpt == "1920x1080"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "384x216"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "640x360"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "1280x720"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (C.currentOpt == "JPEG") && (c.currentOpt == "1360x768"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "384x216"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "640x360"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "1280x720"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (C.currentOpt == "JPEG") && (c.currentOpt == "1360x768"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  D.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  E = devicePackCtrl.GetUISet(camID, "uid_s2_video_codec");
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "1080P FULL HD (16:9)") && (E.currentOpt == "JPEG") && (d.currentOpt == "1600x904"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (E.currentOpt == "JPEG") && (d.currentOpt == "1920x1080"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "384x216"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "640x360"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "1280x720"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (E.currentOpt == "JPEG") && (d.currentOpt == "1360x768"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "384x216"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "640x360"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "1280x720"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (E.currentOpt == "JPEG") && (d.currentOpt == "1360x768"))) {
  F = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  F.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  G = devicePackCtrl.GetUISet(camID, "uid_s3_video_codec");
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "1080P FULL HD (16:9)") && (G.currentOpt == "JPEG") && (e.currentOpt == "1600x904"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (G.currentOpt == "JPEG") && (e.currentOpt == "1920x1080"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "384x216"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "640x360"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "1280x720"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "50") && (G.currentOpt == "JPEG") && (e.currentOpt == "1360x768"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "384x216"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "640x360"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "1280x720"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }
  else if (((a.currentOpt == "1080P FULL HD (16:9)") && (f.currentOpt == "60") && (G.currentOpt == "JPEG") && (e.currentOpt == "1360x768"))) {
  H = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  H.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55"];
  }
  };

    rt["MD8531"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z, A, B, C, D;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "960P (4:3)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["%s","176x144","320x240","640x480","800x600","1280x960"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["%s","176x144","320x240","640x480","800x600","1280x960"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["%s","176x144","320x240","640x480","800x600","1280x960"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","320x240","640x480","800x600","1280x960"];
  }
  else if (((a.currentOpt == "720P HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["%s","176x144","384x216","640x360","1280x720"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["%s","176x144","384x216","640x360","1280x720"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["%s","176x144","384x216","640x360","1280x720"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  g.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  u.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  v = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  v.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  g.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  h = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  h.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  i = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  i.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  j = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  u.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  v = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  v.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  w.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  w.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  p = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((p.currentOpt == "1"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  x.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((p.currentOpt == "2"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((p.currentOpt == "3"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(p.currentOpt) > Number("3")))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  x.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((s.currentOpt == "1"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  y.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((s.currentOpt == "2"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((s.currentOpt == "3"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(s.currentOpt) > Number("3")))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  y.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  v = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((v.currentOpt == "1"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((v.currentOpt == "2"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((v.currentOpt == "3"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(v.currentOpt) > Number("3")))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  A = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  A.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  A = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  A.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  A = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  A.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  A = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  A.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  n = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((n.currentOpt == "1"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  B.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "2"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  B.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "3"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  B.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(n.currentOpt) > Number("3")))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  B.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  q = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((q.currentOpt == "1"))) {
  C = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  C.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((q.currentOpt == "2"))) {
  C = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  C.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((q.currentOpt == "3"))) {
  C = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  C.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(q.currentOpt) > Number("3")))) {
  C = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  C.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((t.currentOpt == "1"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  D.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((t.currentOpt == "2"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  D.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((t.currentOpt == "3"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  D.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(t.currentOpt) > Number("3")))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  D.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["MD8562"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  p = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  if (((a.currentOpt == "60") && (p.currentOpt == "1920x1080"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (p.currentOpt == "1600x904"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (p.currentOpt == "1360x768"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (p.currentOpt == "1280x720"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (p.currentOpt == "640x360"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (p.currentOpt == "384x216"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (p.currentOpt == "176x144"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  q = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  if (((a.currentOpt == "60") && (q.currentOpt == "1920x1080"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (q.currentOpt == "1600x904"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (q.currentOpt == "1360x768"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (q.currentOpt == "1280x720"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (q.currentOpt == "640x360"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (q.currentOpt == "384x216"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (q.currentOpt == "176x144"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  r = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  if (((a.currentOpt == "60") && (r.currentOpt == "1920x1080"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "1600x904"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "1360x768"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "1280x720"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "640x360"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "384x216"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (r.currentOpt == "176x144"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  s = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  if (((a.currentOpt == "60") && (s.currentOpt == "1920x1080"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "1600x904"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "1360x768"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "1280x720"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "640x360"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "384x216"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "60") && (s.currentOpt == "176x144"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  u = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  u = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["PD8136"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["PT8133"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  k = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  m.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["PZ81X1"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u;
  a = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((a.currentOpt == "1"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  b.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((a.currentOpt == "2"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  b.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((a.currentOpt == "3"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  b.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(a.currentOpt) > Number("3")))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  b.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  d.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  d.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  d.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  d.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  f.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  f.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  f.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  f.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  h.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  h.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  o = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((o.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(o.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  q = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((q.currentOpt == "50"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  u = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((q.currentOpt == "60"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  r.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  u = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }
  };

    rt["PZ81XX"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p;
  a = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((a.currentOpt == "1"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((a.currentOpt == "2"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((a.currentOpt == "3"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(a.currentOpt) > Number("3")))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  d.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  d.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  d.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  d.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  f.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  f.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  f.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  f.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  h.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  h.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  o = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((o.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(o.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["SD8161"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  b.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  c = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  c.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  d = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  d.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  e = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  e.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["SD8333-E"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p;
  a = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((a.currentOpt == "1"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((a.currentOpt == "2"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((a.currentOpt == "3"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(a.currentOpt) > Number("3")))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  b.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  c = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((c.currentOpt == "1"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  d.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "2"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  d.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((c.currentOpt == "3"))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  d.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(c.currentOpt) > Number("3")))) {
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  d.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  f.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  f.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  f.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  f.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  h.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  h.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  h = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  h.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  j = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  l.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  l.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  o = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((o.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(o.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["SD8333"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  k = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  l = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  k = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["SD8362"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z, A, B, C, D;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Full HD"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }
  else if (((a.currentOpt == "Exceptional frame rate"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "Full HD") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  else if (((a.currentOpt == "Full HD") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "Exceptional frame rate") && (f.currentOpt == "50"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "Exceptional frame rate") && (f.currentOpt == "60"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","15","20","25","30","60"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((f.currentOpt == "50"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  u = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  v = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((f.currentOpt == "60"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  s.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  t = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  t.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  u = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  u.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  v = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  v.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  w.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  w.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  x.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  x.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((o.currentOpt == "1"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  y.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "2"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((o.currentOpt == "3"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(o.currentOpt) > Number("3")))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  y.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((r.currentOpt == "1"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((r.currentOpt == "2"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((r.currentOpt == "3"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(r.currentOpt) > Number("3")))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  z.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  A = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  A.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  A = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  A.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  A = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  A.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  A = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  A.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  B.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  B.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  B.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  B = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  B.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  C = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  C.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  C = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  C.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  C = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  C.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  C = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  C.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((p.currentOpt == "1"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  D.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((p.currentOpt == "2"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  D.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((p.currentOpt == "3"))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  D.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(p.currentOpt) > Number("3")))) {
  D = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  D.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["SD8363"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }
  else if (((a.currentOpt == "720P HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((f.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((f.currentOpt == "50") && (a.currentOpt == "720P HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "720P HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1920x1080"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1600x904"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1360x768"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1280x720"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "640x360"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "384x216"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "176x144"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1920x1080"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1600x904"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1360x768"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1280x720"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "640x360"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "384x216"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "176x144"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1920x1080"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1600x904"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1360x768"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1280x720"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "640x360"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "384x216"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "176x144"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1920x1080"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1600x904"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1360x768"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1280x720"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "640x360"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "384x216"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "176x144"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["SD8363E"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }
  else if (((a.currentOpt == "720P HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((f.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((f.currentOpt == "50") && (a.currentOpt == "720P HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "720P HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1920x1080"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1600x904"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1360x768"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1280x720"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "640x360"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "384x216"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "176x144"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1920x1080"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1600x904"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1360x768"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1280x720"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "640x360"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "384x216"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "176x144"))) {
  m = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1920x1080"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1600x904"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1360x768"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1280x720"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "640x360"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "384x216"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "176x144"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1920x1080"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1600x904"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1360x768"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1280x720"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "640x360"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "384x216"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "176x144"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["SD8364"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }
  else if (((a.currentOpt == "720P HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((f.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((f.currentOpt == "50") && (a.currentOpt == "720P HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "720P HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1920x1080"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1600x904"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1360x768"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1280x720"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "640x360"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "384x216"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "176x144"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1920x1080"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1600x904"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1360x768"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1280x720"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "640x360"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "384x216"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "176x144"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1920x1080"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1600x904"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1360x768"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1280x720"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "640x360"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "384x216"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "176x144"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1920x1080"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1600x904"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1360x768"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1280x720"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "640x360"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "384x216"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "176x144"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["SD8364E"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "1080P Full HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }
  else if (((a.currentOpt == "720P HD (16:9)"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720"];
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  e.opts = ["176x144","384x216","640x360","1280x720"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((f.currentOpt == "50") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((f.currentOpt == "50") && (a.currentOpt == "720P HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "720P HD (16:9)"))) {
  g = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  m = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  p = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  q = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30","35","40","45","50","55","60"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1920x1080"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1600x904"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1360x768"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "1280x720"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "640x360"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "384x216"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (b.currentOpt == "176x144"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1920x1080"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1600x904"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1360x768"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "1280x720"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "640x360"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "384x216"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (c.currentOpt == "176x144"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1920x1080"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1600x904"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1360x768"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "1280x720"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "640x360"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "384x216"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (d.currentOpt == "176x144"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  e = devicePackCtrl.GetUISet(camID, "uid_s3_frame_size");
  if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1920x1080"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","27"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1600x904"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1360x768"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "1280x720"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "640x360"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "384x216"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((f.currentOpt == "60") && (a.currentOpt == "1080P Full HD (16:9)") && (e.currentOpt == "176x144"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  };

    rt["SF8172"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z;
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((a.currentOpt == "Fisheye mode"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1280x1280","1536x1536","1920x1920"];
  }
  else if (((a.currentOpt == "1080P Full HD"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  b.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  c = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  c.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  d = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  d.opts = ["176x144","384x216","640x360","1280x720","1360x768","1600x904","1920x1080"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_freq");
  a = devicePackCtrl.GetUISet(camID, "uid_video_mode");
  if (((e.currentOpt == "50") && (a.currentOpt == "Fisheye mode"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_svc_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_svc_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "Fisheye mode"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_svc_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_svc_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  else if (((e.currentOpt == "50") && (a.currentOpt == "1080P Full HD"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_svc_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_svc_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((e.currentOpt == "60") && (a.currentOpt == "1080P Full HD"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s1_svc_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s2_svc_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  i = devicePackCtrl.GetUISet(camID, "uid_s0_svc_max_frame_rate");
  if (((i.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_svc_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_svc_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((i.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_svc_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(i.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s0_svc_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((l.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((l.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(l.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s1_svc_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_svc_intra_period");
  w.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_svc_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_svc_intra_period");
  w.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  w = devicePackCtrl.GetUISet(camID, "uid_s1_svc_intra_period");
  w.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  n = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((n.currentOpt == "1"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  x.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "2"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "3"))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  x.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(n.currentOpt) > Number("3")))) {
  x = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  x.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  p = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((p.currentOpt == "1"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  y.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((p.currentOpt == "2"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((p.currentOpt == "3"))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  y.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(p.currentOpt) > Number("3")))) {
  y = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  y.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  q = devicePackCtrl.GetUISet(camID, "uid_s2_svc_max_frame_rate");
  if (((q.currentOpt == "1"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_svc_intra_period");
  z.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((q.currentOpt == "2"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_svc_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((q.currentOpt == "3"))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_svc_intra_period");
  z.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(q.currentOpt) > Number("3")))) {
  z = devicePackCtrl.GetUISet(camID, "uid_s2_svc_intra_period");
  z.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["VC8101"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q;
  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "60"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "50"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/500","1/250","1/100","1/50","1/25","1/15","1/5"];
  }
  else if (((a.currentOpt == "60"))) {
  j = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time");
  j.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  k = devicePackCtrl.GetUISet(camID, "uid_max_exposure_time_profile");
  k.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  l = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time");
  l.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  m = devicePackCtrl.GetUISet(camID, "uid_min_exposure_time_profile");
  m.opts = ["1/32000","1/16000","1/8000","1/4000","1/2000","1/1000","1/480","1/240","1/120","1/60","1/30","1/15","1/5"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  n = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  n.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((f.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((f.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(f.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["VC8201"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u;
  a = devicePackCtrl.GetUISet(camID, "uid_lens_modelname");
  if (((a.currentOpt == "CU8131_VC8201"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_fisheye_mount_type");
  b.opts = [];
  c = devicePackCtrl.GetUISet(camID, "uid_gamma");
  c.opts = [];
  d = devicePackCtrl.GetUISet(camID, "uid_low_light_com");
  d.opts = ["0","1"];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  e.opts = ["%s","176x144","320x200","640x400","1280x720","1280x800"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_roi_home");
  f.opts = ["%s"];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_roi_size");
  g.opts = ["%s"];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  h.opts = ["%s","176x144","320x200","640x400","1280x720","1280x800"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_roi_home");
  i.opts = ["%s"];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_roi_size");
  j.opts = ["%s"];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  k.opts = ["176x144","320x200","640x400","1280x720","1280x800"];
  }
  else if (((a.currentOpt == "CU8171_VC8201"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_fisheye_mount_type");
  b.opts = ["ceiling","wall","floor"];
  c = devicePackCtrl.GetUISet(camID, "uid_gamma");
  c.opts = ["0","45","50","60","70","80","90","100"];
  d = devicePackCtrl.GetUISet(camID, "uid_low_light_com");
  d.opts = [];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  e.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1536x1536","1696x1696"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_roi_home");
  f.opts = [];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_roi_size");
  g.opts = [];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  h.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1536x1536","1696x1696"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_roi_home");
  i.opts = [];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_roi_size");
  j.opts = [];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  k.opts = ["192x192","256x256","384x384","512x512","768x768","1056x1056","1536x1536","1696x1696"];
  }
  else if (((a.currentOpt == "-"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_fisheye_mount_type");
  b.opts = [];
  c = devicePackCtrl.GetUISet(camID, "uid_gamma");
  c.opts = [];
  d = devicePackCtrl.GetUISet(camID, "uid_low_light_com");
  d.opts = [];
  e = devicePackCtrl.GetUISet(camID, "uid_s0_frame_size");
  e.opts = ["320x240"];
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  l.opts = ["%s","1"];
  m = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  m.opts = ["%s","1"];
  n = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  n.opts = ["%s","1"];
  f = devicePackCtrl.GetUISet(camID, "uid_s0_roi_home");
  f.opts = [];
  g = devicePackCtrl.GetUISet(camID, "uid_s0_roi_size");
  g.opts = [];
  h = devicePackCtrl.GetUISet(camID, "uid_s1_frame_size");
  h.opts = ["320x240"];
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  o.opts = ["%s","1"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  p.opts = ["%s","1"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  q.opts = ["%s","1"];
  i = devicePackCtrl.GetUISet(camID, "uid_s1_roi_home");
  i.opts = [];
  j = devicePackCtrl.GetUISet(camID, "uid_s1_roi_size");
  j.opts = [];
  k = devicePackCtrl.GetUISet(camID, "uid_s2_frame_size");
  k.opts = ["320x240"];
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  r.opts = ["%s","1"];
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  s.opts = ["%s","1"];
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  t.opts = ["%s","1"];
  }

  a = devicePackCtrl.GetUISet(camID, "uid_lens_modelname");
  u = devicePackCtrl.GetUISet(camID, "uid_freq");
  if (((a.currentOpt == "CU8131_VC8201") && (u.currentOpt == "50"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  n = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25"];
  }
  else if (((a.currentOpt == "CU8131_VC8201") && (u.currentOpt == "60"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  n = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15","20","25","30"];
  }
  else if (((a.currentOpt == "CU8171_VC8201") && (u.currentOpt == "50"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12"];
  m = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12"];
  n = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12"];
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12"];
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12"];
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12"];
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12"];
  }
  else if (((a.currentOpt == "CU8171_VC8201") && (u.currentOpt == "60"))) {
  l = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","12","15"];
  m = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","12","15"];
  n = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  n.opts = ["%s","1","2","3","5","8","10","12","15"];
  o = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  o.opts = ["%s","1","2","3","5","8","10","12","15"];
  p = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  p.opts = ["%s","1","2","3","5","8","10","12","15"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  q.opts = ["%s","1","2","3","5","8","10","12","15"];
  r = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  r.opts = ["%s","1","2","3","5","8","10","12","15"];
  s = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  s.opts = ["%s","1","2","3","5","8","10","12","15"];
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  t.opts = ["%s","1","2","3","5","8","10","12","15"];
  }
  };

    rt["VS8100"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g;
  a = devicePackCtrl.GetUISet(camID, "uid_analog_video_mode");
  if (((a.currentOpt == "ntsc"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "pal"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }
  };

    rt["VS8102"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v;
  a = devicePackCtrl.GetUISet(camID, "uid_analog_video_mode");
  if (((a.currentOpt == "ntsc"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  k = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  l = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "pal"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  h.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  i = devicePackCtrl.GetUISet(camID, "uid_s2_mjpeg_max_frame_rate");
  i.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  j.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  k = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  k.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  l = devicePackCtrl.GetUISet(camID, "uid_s3_mjpeg_max_frame_rate");
  l.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  m.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }

  n = devicePackCtrl.GetUISet(camID, "uid_enable_time_shift_catching_stream");
  if (((n.currentOpt == "0"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  p = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  r = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  s = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  u = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  v = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((n.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S"];
  p = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S"];
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S"];
  r = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S"];
  s = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S"];
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S"];
  u = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S"];
  v = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  p.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  p.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  p = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  p.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  if (((g.currentOpt == "1"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  r.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "2"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((g.currentOpt == "3"))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  r.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(g.currentOpt) > Number("3")))) {
  r = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  r.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  j = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_max_frame_rate");
  if (((j.currentOpt == "1"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "2"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((j.currentOpt == "3"))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(j.currentOpt) > Number("3")))) {
  t = devicePackCtrl.GetUISet(camID, "uid_s2_mpeg4_intra_period");
  t.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  m = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_max_frame_rate");
  if (((m.currentOpt == "1"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  v.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "2"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((m.currentOpt == "3"))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  v.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(m.currentOpt) > Number("3")))) {
  v = devicePackCtrl.GetUISet(camID, "uid_s3_mpeg4_intra_period");
  v.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  o = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  o.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  if (((e.currentOpt == "1"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "2"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((e.currentOpt == "3"))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(e.currentOpt) > Number("3")))) {
  q = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  q.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_s2_h264_max_frame_rate");
  if (((h.currentOpt == "1"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  s.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "2"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "3"))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  s.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(h.currentOpt) > Number("3")))) {
  s = devicePackCtrl.GetUISet(camID, "uid_s2_h264_intra_period");
  s.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  k = devicePackCtrl.GetUISet(camID, "uid_s3_h264_max_frame_rate");
  if (((k.currentOpt == "1"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  u.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "2"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((k.currentOpt == "3"))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  u.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(k.currentOpt) > Number("3")))) {
  u = devicePackCtrl.GetUISet(camID, "uid_s3_h264_intra_period");
  u.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    rt["VS8401"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f, g, h, i, j, k, l;
  a = devicePackCtrl.GetUISet(camID, "uid_analog_video_mode");
  if (((a.currentOpt == "ntsc"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "pal"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  e = devicePackCtrl.GetUISet(camID, "uid_s1_h264_max_frame_rate");
  e.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  f = devicePackCtrl.GetUISet(camID, "uid_s1_mjpeg_max_frame_rate");
  f.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  g = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_max_frame_rate");
  g.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }

  h = devicePackCtrl.GetUISet(camID, "uid_enable_time_shift_catching_stream");
  if (((h.currentOpt == "0"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  i.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((h.currentOpt == "1"))) {
  i = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  i.opts = ["1/4 S","1/2 S","1 S"];
  j = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  j.opts = ["1/4 S","1/2 S","1 S"];
  k = devicePackCtrl.GetUISet(camID, "uid_s1_h264_intra_period");
  k.opts = ["1/4 S","1/2 S","1 S"];
  l = devicePackCtrl.GetUISet(camID, "uid_s1_mpeg4_intra_period");
  l.opts = ["1/4 S","1/2 S","1 S"];
  }
  };

    rt["VS8801"] = function checkInteract(devicePackCtrl, camID) {
  var a, b, c, d, e, f;
  a = devicePackCtrl.GetUISet(camID, "uid_analog_video_mode");
  if (((a.currentOpt == "ntsc"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25","30"];
  }
  else if (((a.currentOpt == "pal"))) {
  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  b.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  c = devicePackCtrl.GetUISet(camID, "uid_s0_mjpeg_max_frame_rate");
  c.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  d.opts = ["%s","1","2","3","5","8","10","15","20","25"];
  }

  d = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_max_frame_rate");
  if (((d.currentOpt == "1"))) {
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  e.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "2"))) {
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  e.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((d.currentOpt == "3"))) {
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  e.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(d.currentOpt) > Number("3")))) {
  e = devicePackCtrl.GetUISet(camID, "uid_s0_mpeg4_intra_period");
  e.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }

  b = devicePackCtrl.GetUISet(camID, "uid_s0_h264_max_frame_rate");
  if (((b.currentOpt == "1"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  f.opts = ["1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "2"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  f.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((b.currentOpt == "3"))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  f.opts = ["1/2 S","1 S","2 S","3 S","4 S"];
  }
  else if (((Number(b.currentOpt) > Number("3")))) {
  f = devicePackCtrl.GetUISet(camID, "uid_s0_h264_intra_period");
  f.opts = ["1/4 S","1/2 S","1 S","2 S","3 S","4 S"];
  }
  };

    return rt;
  }

  }(this));
