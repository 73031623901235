import ConnectionManager from '@vivotek/lib-streaming-connection-manager';
import Liveview from './classic/liveview';

class MedamaFactory {
  constructor({
    mode = 'liveview',
    handleRtspChannelClose = () => {},
  } = {}) {
    this.mode = mode;
    this.rtspChannel = null;
    this.handleRtspChannelClose = handleRtspChannelClose;
  }

  createViewCell(options) {
    const url = options.url || '';
    const connectPromise = () => {
      if (url.startsWith('ws')) {
        return ConnectionManager.createWebSocket(url);
      }
      return ConnectionManager.createRtcConnection(options)
        .then((connection) => {
          let rtspChannel;
          if (this.mode === 'liveview') {
            rtspChannel = connection.getLiveviewChannel();
          } else {
            rtspChannel = connection.getPlaybackChannel();
          }
          return rtspChannel;
        });
    };

    return connectPromise(options.device)
      .then((rtspChannel) => {
        this.rtspChannel = rtspChannel;
        rtspChannel.on('close', this.handleRtspChannelClose);
        if (this.mode === 'liveview') {
          return this.createLiveview(rtspChannel, options);
        }
        return this.createPlayback(rtspChannel, options);
      });
  }

  createLiveview(rtspChannel, config) {
    const liveview = new Liveview({
      rtspChannel,
      ...config
    });
    return liveview;
  }

  createPlayback(rtspChannel, config) {
    const playback = new Playback({
      rtspChannel,
      ...config
    });
    return playback;
  }

  destroy() {
    const { rtspChannel } = this;

    if (rtspChannel) {
      rtspChannel.off('close', this.handleRtspChannelClose);
      rtspChannel.close();

      this.rtspChannel = null;
    }
  }
}

export default MedamaFactory;
