import { isSafari, appendBuffer } from '@vivotek/lib-rtsp-protocol/src/v1/rtsp_tools';

function getAudioWindow(n, f) {
  const arr = [];

  for (let i = 0; i < n; i += 1) {
    arr[i] = (i < n / 2) ? 1 - f ** i : 1 - f ** (n - i);
  }

  return arr;
};

class AudioPlayer {
  constructor({ snapshotMode = false, mute=false, volume=50 }) {
    this.snapshotMode = snapshotMode;
    this.mute = mute;
    this.volume = volume;

    this.COMBINE_PACKET_NUMBER = 2;

    this.audioContext = null;
    this.gainNode = null;
    this.audioContextStart = 0;
    this.audioQueue = []; // for packet compine

    this.createAudio();
  }

  get inited() {
    return this.audioContextStart !== 0;
  }

  createAudio() {
    const { mute, volume } = this;
    let audioContext, gainNode;

    try {
      const AudioContext = window.AudioContext || window.webkitAudioContext;

      audioContext = new AudioContext();
      gainNode = audioContext.createGain();
      gainNode.connect(audioContext.destination);
      gainNode.gain.value = mute ? 0 : volume;
    } catch (err) {
      console.warn('createGain fail', err);
    }

    this.audioContext = audioContext;
    this.gainNode = gainNode;
  }

  removeAudio() {
    const { audioContext, gainNode } = this;

    if (gainNode) {
      gainNode.disconnect();
    }

    if (audioContext) {
      audioContext.close();
    }

    this.audioContext = null;
    this.gainNode = null;
    this.audioContextStart = 0;
  }

  genAudioPlayTime(
    audioTimeInfo, notifyTimestamp, videoCurrentTimeMsec, audioCurrentTimeMsec
  ) {
    const currentTimestamp = notifyTimestamp.stream
      + (videoCurrentTimeMsec - notifyTimestamp.video);
    return audioCurrentTimeMsec + (audioTimeInfo.stream - currentTimestamp);
  }

  initAudio(currentTimeMsec, refNotifyTimestamp) {
    const { audioContext, audioQueue, inited } = this;

    if (!audioContext || audioContext.state === 'suspended' || inited) {
      return;
    }

    // element's format { packet, info, sampleRate, duration } in audioTmp
    audioQueue.sort((a, b) => a.info.timestamp.stream - b.info.timestamp.stream);

    if (!audioQueue.length || !refNotifyTimestamp) {
      return;
    }

    const firstAudioInfo = audioQueue[0].info;
    const audioPlayTime = this.genAudioPlayTime(
      firstAudioInfo.timestamp, refNotifyTimestamp, currentTimeMsec, audioContext.currentTime * 1000
    );
    // the audio reference start time
    this.audioContextStart = audioPlayTime - firstAudioInfo.timestamp.audio;
  }

  processAudio() {
    if (this.snapshotMode) {
      return;
    }

    const { inited, audioQueue, COMBINE_PACKET_NUMBER, audioContextStart } = this;
    const limit = 1;

    if (!inited || audioQueue.length < COMBINE_PACKET_NUMBER) {
      return;
    }

    while (audioQueue[0].info.timestamp.audio + audioContextStart < 0) {
      audioQueue.shift();

      if (audioQueue.length < COMBINE_PACKET_NUMBER ) {
        return;
      }
    };

    const packetInfo = audioQueue[0].info;
    const packetSampleRate = audioQueue[0].sampleRate;

    let packetCombine = new ArrayBuffer();
    let packetPair;

    while (audioQueue.length > limit) {
      packetPair = audioQueue.shift();
      packetCombine = appendBuffer(packetCombine, packetPair.packet.buffer);
    }

    if (audioQueue.length) {
      packetCombine = appendBuffer(packetCombine, audioQueue[0].packet.slice(0, 256).buffer);
    }

    const packetStartTime = audioContextStart + packetInfo.timestamp.audio;
    this.playAudioPacket([new Float32Array(packetCombine), packetStartTime, packetSampleRate]);
  }

  playAudioPacket([packet, startTime, sampleRate]) {
    const { audioContext, gainNode } = this;

    if (!audioContext || !gainNode || this.speedUp) {
      return;
    }

    const source = audioContext.createBufferSource();
    const win = getAudioWindow(packet.length, 0.99);
    let audioBuffer;

    if (startTime < 0) {
      return;
    }

    if (isSafari) {
      audioBuffer = audioContext.createBuffer(1, packet.length, 44100);
      source.playbackRate.value = 8000 / 44100;
    } else {
      audioBuffer = audioContext.createBuffer(1, packet.length, sampleRate);
    }

    // put pcm data(Float32Array) in audiobuffer
    for (let sample = 0; sample < packet.length; sample += 1) {
      audioBuffer.getChannelData(0)[sample] = packet[sample] * win[sample];
    }

    const audioStartTime = startTime || 0;

    source.buffer = audioBuffer;
    source.connect(gainNode);
    source.start(audioStartTime / 1000);
  };

  switchAudio() {
    this.audioQueue.length = 0;
    this.removeAudio();
    this.createAudio();
  }

  appendAudioPacket(audioPacket) {
    this.audioQueue.push(audioPacket); // { packet, sampleRate, duration, info }
  }

  setMute() {
    const { gainNode } = this;

    this.mute = true;
    this.resume();

    if (gainNode) {
      gainNode.gain.value = 0;
    }
  }

  unmute() {
    const { gainNode, volume } = this;

    this.mute = false;
    this.resume();

    if (gainNode) {
      gainNode.gain.value = volume;
    }
  }

  setVolume(value) {
    const { gainNode } = this;

    this.mute = false;
    this.volume = value;
    this.resume();

    if (gainNode) {
      gainNode.gain.value = value;
    }
  }

  suspend() {
    this.audioContext.suspend();
  }

  resume() {
    const { audioContext } = this;

    if (!audioContext || audioContext.state !== 'suspended') {
      return;
    }

    audioContext.resume();
  }

  destroy() {
    this.removeAudio();
  }
}

export default AudioPlayer;
