import Playback from './playback';
import setSsmuxDebugLevel from '../utils/set_ssmux_debug_level';
import RawPlayer from '../player/RawPlayer';
import { isSafari } from '@vivotek/lib-rtsp-protocol/src/v1/rtsp_tools';
import MicroEvent from '@vivotek/lib-utility/microevent';

function Snapshot() {
  Playback.apply(this, arguments);
  this.getInterval = 0;
}

Object.assign(Snapshot.prototype, Playback.prototype);

Snapshot.prototype.getImage = function getImage({ startTime, tzoffs, resolution }) {
  this.prevIsPreroll = true;
  const playbackStartTime = startTime instanceof Date ? startTime.getTime() : startTime;
  this.isLocalTime = tzoffs !== undefined;
  this.tzoffs = tzoffs;
  this.startTime = startTime;
  this.mode = this.startTime === 0 ? 'liveview' : 'playback';
  if (this.mode === 'playback') {
    this.updateUrlTimeRange(playbackStartTime);
  }

  if (isSafari) { this.safariPause = true; }

  const { url } = this;
  const sendOptions = () => this.sendOptions(url);
  const sendDescribe = () => this.sendDescribe(url);
  const sendSetup = () => this.sendSetup(url)
    .then(([status, session]) => {
      this.rtspSession = session;
    });
  const sendPlay = () => this.sendPlay(url, this.rtspSession);
  const createSsmux = () => this.createSsmux(this.ssmuxType)
    .then(setSsmuxDebugLevel)
    .then((ssmux) => {
      this.ssmux = ssmux;
    });

  const getCodecInfo = () => new Promise((resolve) => {
    const onCodecInfo = (info) => {
      this.off(onCodecInfo);
      resolve(info);
    };
    this.on('codecInfo', onCodecInfo);
  });

  const playSteps = () => sendOptions()
    .then(sendDescribe)
    .then(sendSetup)
    .then(createSsmux)
    .then(sendPlay)
    .then(getCodecInfo)
    .then((info) => this.createRawPlayer({ codec: info.codec, ...info.resolution}))
    .then((player) => player.snapshot(resolution))
    .finally(() => this.stop())
    .catch((e) => {
      console.error(e);
      return Promise.reject(e);
    });

  if (this.status === 'TEARDOWN') {
    return playSteps();
  }
  return this.stop().then(() => playSteps());
};

Snapshot.prototype.processInfo = function processInfo(info) {
  if (info.timestamp && info.timestamp.video !== undefined) {
    const { codec } = info;
    if (this.mode === 'playback' && this.startTime + 10 * 1000 < info.timestamp.display.stream) {
      return this.stopping ? undefined : this.stop().then(() => {
        this.trigger('error', new Error('Failed to get stream.'));
      });
    }
    // check codec changed
    if (!this.currentCodec) {
      this.currentCodec = codec;
      const codecInfo = {
        resolution: info.resolution,
        codec: info.codec
      };
      this.trigger('codecInfo', codecInfo);
    }
  }
  Playback.prototype.processInfo.apply(this, arguments);
};

Snapshot.prototype.processRawVideoEvent = function processRawVideoEvent(rawPacket) {
  const info = this.videoNotify;

  if (!info || !this.prevIsPreroll) { return; }
  // keep first not preroll for display snapshot
  const packet = rawPacket.slice();
  this.prevIsPreroll = info.preroll;
  if (!this.player) {
    if (this.packet_bucket) {
      this.packet_bucket.push({ packet, info });
      return;
    }
    this.packet_bucket = [{ packet, info }];
    return;
  }

  if (typeof this.player.decodeOneFrame !== 'function') { return; }

  this.player.decodeOneFrame(packet, info);

  this.videoNotify = null;
};

Snapshot.prototype.createSsmux = function createSsmux(ssmuxType) {
  return Playback.prototype.createSsmux.call(this, ssmuxType).then(
    (ssmux) => {
      const processRawVideoEvent = this.processRawVideoEvent.bind(this);
      ssmux.setFMP4Callback(null);
      ssmux.setAVCCallback(processRawVideoEvent);
      ssmux.setHEVCCallback(processRawVideoEvent);
      return ssmux;
    }
  );
};

Snapshot.prototype.createRawPlayer = function createRawPlayer({ width, height, codec}) {
  const snapshotCanvas = document.createElement('canvas');

  snapshotCanvas.setAttribute('width', width);
  snapshotCanvas.setAttribute('height', height);

  const options = this.gainRawPlayerOptions({ canvas: snapshotCanvas, width, height, codec });

  const rawplayer = new RawPlayer(options);

  rawplayer.on('error', (warn) => this.trigger('error', new Error(warn)));

  const handlePreQueuedFrames = () => new Promise((resolve) => {
    this.player = rawplayer;
    this.player.on('play', () => {
      resolve(this.player);
    });
    this.prevIsPreroll = true;
    this.packet_bucket.forEach(({ packet, info }) => {
      this.player.decodeOneFrame(packet, info);
    });
    this.packet_bucket = null;
    this.player.play();
  });

  const createPlayer = new Promise((resolve) => {
    rawplayer.on('inited', () => resolve());
  }).then(handlePreQueuedFrames);

  return createPlayer;
};

Snapshot.prototype.resetAudio = function resetAudio() {};
Snapshot.prototype.processAudio = function processAudio() {};
Snapshot.prototype.processAudioEvent = function processAudioEvent() {};
Snapshot.prototype.switchAudio = function switchAudio() {};

export default MicroEvent.mixin(Snapshot);
