import xml2json from '../utils/xml2json';
import Notify from '../model/Notify';
import Metj from '../model/Metj';

class NotifyHandler {
  constructor() {
    this.clear();
  }

  clear() {
    this.timeMapInfo = new Map();
    this.timestampMapMetj = new Map();
    this.prevNotify = null;
  }

  appendNotify(notify={}) {
    const time = notify?.timestamp?.video ; // msec

    if (time !== undefined) {
      this.timeMapInfo.set(time, notify);
    }
  }

  appendMetj(metj={}) {
    let timestamp;
    switch (metj.Tag) {
      case 'MetaData':
        timestamp = new Date(metj.Frame.UtcTime).getTime();
        break;
      case 'Event':
        try {
          const firstData = metj.Data[0];
          const arrKey = Object.keys(firstData).filter((key) => Array.isArray(firstData[key]))[0];
          const [firstInfo] = firstData[arrKey];

          timestamp = new Date(firstInfo.Time).getTime();
        } catch (err) {
          console.warn(err);
          return;
        }
        break;
      default:
        break;
    }
    const { timestampMapMetj } = this;

    if (timestamp !== undefined) {
      if (timestampMapMetj.has(timestamp)) {
        timestampMapMetj.get(timestamp).push(metj);
      } else {
        timestampMapMetj.add(timestamp, [metj]);
      }
    }
  }

  appendMetx(xmlDoc) {
    const xmlJsonStr = xml2json(xmlDoc);
    const xmlJson = JSON.parse(xmlJsonStr);
    const nodes = xmlDoc.querySelectorAll('[UtcTime]');

    if (nodes.length <= 0) {
      return;
    }

    const utcTime = nodes[0].getAttribute('UtcTime');
    const timestamp = new Date(utcTime).getTime();
    const { timestampMapMetj } = this;

    if (timestampMapMetj.has(timestamp)) {
      timestampMapMetj.get(timestamp).push(xmlJson);
    } else {
      timestampMapMetj.add(timestamp, [xmlJson]);
    }
  }

  getExpiredEvents(currentTime=0) {
    const expired = [];
    const { timeMapInfo, timestampMapMetj } = this;

    timeMapInfo.forEach((frameInfo, key) => {
      if (key > currentTime) {
        return;
      }

      const notify = this.createNotify(frameInfo);
      const { stream } = frameInfo.timestamp;
      expired.push(notify);

      timeMapInfo.delete(key);
      // trigger metj event
      timestampMapMetj.forEach((metjs, timestamp) => {
        if (timestamp > stream) {
          return;
        }

        metjs.forEach((metjInfo) => {
          const metj = this.createMetj(metjInfo);

          expired.push(metj);
        });
        timestampMapMetj.delete(timestamp);
      });

      this.prevNotify = notify;
    });
    expired.sort((na, nb) => na.timestamp.stream - nb.timestamp.stream);

    return expired;
  }

  getReferenceNotify() {
    const { timeMapInfo, prevNotify } = this;
    const { value } = timeMapInfo.values().next();
    // get frame info from videoTimeMap or pupped notify
    const firstFrameInfo = value;
    const prevFrameInfo = prevNotify;

    if (!prevFrameInfo && !firstFrameInfo) {
      return;
    }

    return firstFrameInfo ? firstFrameInfo : prevFrameInfo;
  }

  createNotify(frameInfo) {
    const timestamp = {
      ...frameInfo.timestamp,
      ...frameInfo.timestamp.display
    };
    delete timestamp.display;

    return new Notify({ ...frameInfo, timestamp });
  }

  createMetj(metj) {
    return new Metj(metj);
  }

  destroy() {
    this.timeMapInfo.clear();
    delete this.timeMapInfo;
    this.timestampMapMetj.clear();
    delete this.timestampMapMetj;
    delete this.prevNotify;
  }
}

export default NotifyHandler;
