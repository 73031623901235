export default Object.freeze({
  SUPPORT_VIDEO_CODEC: ['H264', 'HEVC', 'H265'],
  SUPPORT_AUDIO_CODEC: [
    'DMYV', 'G7221', 'G729A', 'AAC', 'GAMR', 'SAMR', 'G711', 'G711A', 'G726',
    'DMYA', 'IVA1', 'METX', 'ALLCODEC'
  ],

  WORKER_264_SRC: 'libs/h264decoder-1.0.0.1.js',
  WORKER_265_SRC: 'libs/h265decoder-1.0.0.3.js'
});
