import Liveview from './liveview';
import MicroEvent from '@vivotek/lib-utility/microevent';
import LocalTime from '@vivotek/lib-utility/local_time';

const reLocaltime = /LOCTIME=yes/i;
const reStime = /STIME=(\d{4})(\d\d)(\d\d)_(\d\d)(\d\d)(\d\d)_(\d\d\d)/i;
const genStime = function(timestamp) {
  const newSDate = new Date(timestamp);
  return [
    newSDate.getUTCFullYear().toString().padStart(4, '0'),
    (newSDate.getUTCMonth() + 1).toString().padStart(2, '0'),
    newSDate.getUTCDate().toString().padStart(2, '0'),
    '_',
    newSDate.getUTCHours().toString().padStart(2, '0'),
    newSDate.getUTCMinutes().toString().padStart(2, '0'),
    newSDate.getUTCSeconds().toString().padStart(2, '0'),
    '_',
    newSDate.getUTCMilliseconds().toString().padStart(3, '0'),
  ].join('');
};

class VASTStream extends Liveview {
  constructor(options) {
    super(options);

    this.streamTime = null;
    this.dropFirstAudio = false;
    this.continued = false;
    this.isLocalTime = false;
  }

  onPause() {
    this.trigger('pause');
  }

  get isPlayback() {
    return /Database/i.test(this.options.url);
  }

  onNotify({ type, event }) {
    super.onNotify({ type, event });

    if (type === 'notify') {
      this.streamTime = new LocalTime({
        timestamp: event.timestamp.stream,
        tzoffs: event.timestamp.tzoffs,
      });
    }
  }

  play() {
    const { url } = this.options;
    const isLocalTime = reLocaltime.test(url);

    if (this.isPlayback) {
      if (reStime.test(url)) {
        const [, yyyy, MM, dd, hh, mm, ss, msec] = url.match(reStime);
        // start time's timestamp
        let options;

        if (isLocalTime) {
          options = `${yyyy}-${MM}-${dd}T${hh}:${mm}:${ss}`;
          this.streamTime = new LocalTime(options);
        } else {
          options = Date.UTC(yyyy, parseInt(MM)-1 , dd, hh, mm, ss, msec);
          this.streamTime = new LocalTime(options).useUniTimezone();
        }
      }

      this.isLocalTime = isLocalTime;
    }
    return super.play();
  }

  pause() {
    const { rtspProcessor } = this;

    return rtspProcessor.pause()
      .then(() => {
        this.streamPlayer.pause();
        this.audioPlayer.suspend();
        this.clearPlayerTimeout();
      });
  }

  resume() {
    return this.rtspProcessor.play().then(() => {
      this.streamPlayer.play();
      this.audioPlayer.resume();
      this.setPlayerTimeout();
    });
  }

  stop() {
    return super.stop().finally(() => {
      this.streamTime = null;
    });
  }

  processRtspPacket(packet) {
    const { continued } = this;

    this.streamProcessor.appendPacket(packet, continued);

    if (continued) {
      this.continued = false;
    }
  }

  move(sec) {
    const {
      streamTime, isLocalTime, isPlayback, streamPlayer, audioPlayer, rtspProcessor
    } = this;

    if (!streamTime || !isPlayback) {
      return Promise.reject();
    }

    const newStime = streamTime.forwardMilliseconds(sec * 1000);
    let newStimeStr;

    if (isLocalTime) {
      newStimeStr = newStime.toString().replace(/-|:/g, '').replace('T', '_');
    } else {
      newStimeStr = genStime(newStime.timestamp);
    }

    return rtspProcessor.stop().then(() => {
      const { url } = this.options;

      streamPlayer.switch();
      rtspProcessor.setUrl(url.replace(reStime, `STIME=${newStimeStr}_000`));

      this.continued = true;
      return this.play().then(() => audioPlayer.switchAudio());
    });
  }

  setPlaybackRate(rate) {
    if (!this.isPlayback) return Promise.reject();

    return this.rtspProcessor.change(rate)
      .then(() => {
        const { streamPlayer, audioPlayer } = this;

        streamPlayer.playbackRate = rate;

        if (rate === 1) {
          audioPlayer.switchAudio();
          audioPlayer.unmute();
          // fix playbackRate from (!= 1) to (== 1), server will send an old audio packet
          this.dropFirstAudio = true;
        } else {
          audioPlayer.setMute();
        }
      });
  }

  processStreamAudio({ packet, sampleRate, duration }) {
    const { streamInfo, audioPlayer } = this;

    if (this.dropFirstAudio) {
      this.dropFirstAudio = false;
    } else {
      audioPlayer.appendAudioPacket({
        packet, sampleRate, duration,
        info: streamInfo,
      });
    }
  }
}

export default MicroEvent.mixin(VASTStream);
