import MicroEvent from '@vivotek/lib-utility/microevent';
import Gouqi from './gouqi';

var Iris = function (options) {
  var gl;
  var canvas;

  var self = this;

  var gouqi = [];

  var isImage = !!options.isImage;

  function initGL(canvas) {
    try {
      gl = canvas.getContext("webgl", {preserveDrawingBuffer:true});
      gl.viewportWidth = canvas.width;
      gl.viewportHeight = canvas.height;
    } catch (e) {
      try {
        gl = canvas.getContext("experimental-webgl", {preserveDrawingBuffer:true});
        gl.viewportWidth = canvas.width;
        gl.viewportHeight = canvas.height;
      } catch (e) { }
    }
    if (!gl) {
      alert("Could not initialise WebGL, sorry :-(\nPlease to \"http://get.webgl.org\" get one");
      /*if("click ok to continue")
        window.location = "http://get.webgl.org";*/
    }
  }

  /*======= Creating a canvas =========*/
  canvas = this.canvas = document.createElement("canvas");
  canvas.style.background = '#000000';

  initGL(canvas);

  gl.viewportWidth = canvas.width;
  gl.viewportHeight = canvas.height;
  gl.enable(gl.DEPTH_TEST);
  gl.clearColor(0.0, 0.0, 0.0, 1.0);
  gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

  // gouqi
  gouqi = new Gouqi({}, tick);
  gouqi.setGL(gl, canvas);

  // setInterval(tick, 15);
  var requestAnimationFrame = (function () {
    return window.requestAnimationFrame ||
           window.webkitRequestAnimationFrame ||
           window.mozRequestAnimationFrame ||
           window.oRequestAnimationFrame ||
           window.msRequestAnimationFrame ||
           function (callback) {
             return window.setTimeout(callback, 1000 / 60);
           };
  })();

  var cancelAnimationFrame  = (function () {
    return window.cancelAnimationFrame  ||
           window.mozCancelAnimationFrame ||
           function (timeout) {
             window.clearTimeout(timeout);
           };
  })();

  var motionWindowExpire = 500;
  var polygons = [];

  var video = options.video;
  var cover = options.cover;

  var requestId;
  var start = function () {
    onresize();
    requestId = requestAnimationFrame(tick);
  };
  var stop = function () {
    cancelAnimationFrame(requestId);

    requestId = null;
  };
  var onresize = function () {
    resize(canvas.clientWidth, canvas.clientHeight);
  };

  video.addEventListener('play', start);

  if (!video.paused) {
    start();
  }

  window.addEventListener("resize", onresize);

  var resolutionWidth, resolutionHeight;

  //----------------------------------------------------------------------------------
  function tick() {
    var now = new Date();
    var texture;

    gouqi.clearLines();

    // drow lines
    polygons = polygons.filter(function (polygon) {
      return polygon.expire >= now;
    });

    polygons.forEach(function (polygon) {
      var coordinates = polygon.coordinates;
      var length = coordinates.length;
      var p1X, p1Y, p2X, p2Y;

      function smooth (val) {
        // addLine receive value in -1 ~ 1
        return val * 2 - 1;
      }

      for (var i = 0; i + 1 < length; i += 2) {
        p1X = smooth(coordinates[i]);
        p1Y = smooth(1 - coordinates[i + 1]);
        p2X = smooth(coordinates[(i + 2) % length]);
        p2Y = smooth(1 - coordinates[(i + 3) % length]);
        gouqi.addLine(p1X, p1Y, p2X, p2Y);
      }
    });

    if (cover != undefined)
    {
      gouqi.renderVideoAndCover(video, cover);
    }
    else
    {
      gouqi.renderVideo(video);
    }

    requestId = requestAnimationFrame(tick);
  }

  function resize(w,h) {
    canvas.width = w;
    canvas.height = h;

    gl.viewportWidth = canvas.width;
    gl.viewportHeight = canvas.height;

    gouqi.setROI(0, 0, w, h);
  }
  //----------------------------------------------------------------------------------
  this.destroy = function () {
    video.removeEventListener('play', start);

    this.reset();
    canvas.remove();
    gouqi.release();

    window.removeEventListener('resize', onresize);
  };

  this.addPolygon = function (polyArr) {
    var now = new Date();

    polygons.push({
      coordinates: polyArr,
      expire: new Date(now.getTime() + motionWindowExpire)
    });
  };

  this.snapshot = function (isOrigImg) {
    var canvasEl = document.createElement('canvas'),
        canvasTx = canvasEl.getContext('2d');
    var source;

    if (isOrigImg) {
      source = video;
      // set image's width/height by streaming's resolution
      canvasEl.width = resolutionWidth;
      canvasEl.height = resolutionHeight;
    }
    else {
      source = canvas;
      // set image's width/height by canvas element's clientWidth/clientHeight
      canvasEl.width = canvas.clientWidth;
      canvasEl.height = canvas.clientHeight;
    }

    canvasTx.drawImage(source, 0, 0, canvasEl.width, canvasEl.height);
    canvasEl.toBlob(function(blob) {
      saveAs(blob, "screenshot.png", "image/png");
    });
  };

  this.setZoomROI = function (x, y, w, h) {
    gouqi.setZoomROI(x, y, w, h);
  };

  this.resize = function(w, h) {
    resize(w, h);
  };

  this.reset = function () {
    stop();
    gl.clear(gl.COLOR_BUFFER_BIT);
  };
};

export default MicroEvent.mixin(Iris);
